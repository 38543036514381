/**
 * Report dropdown
 * 
 * This component is used to select reports and act on them.
 */

import * as React from "react";
import { useDispatch, useAppState } from "../../hooks/useAppState";
import * as actions from "../../store/actions";
import { Project, getProjectTitle, getWorkspaceName } from "../../model";
import { Move, Trash, Type } from "react-feather";
import theme from "../../theme";
import styled from "styled-components";
import useDropdownSystem, {
  createDropdownState,
} from "../../hooks/useDropdownSystem";
import useI18n from "../../hooks/useI18n";
import appTexts from "../../texts";

export const useReportDropdown = createDropdownState();

export const ReportDropdown = ({ project }: { project: Project; }) => {
  const t = useI18n();
  const dropdown = useReportDropdown();
  const ref = useDropdownSystem(dropdown.close);
  const dispatch = useDispatch();
  const workspaces = useAppState((s) => s.workspaces);
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState(
    null as null | "move" | "rename" | "delete"
  );
  const [workspace, setWorkspace] = React.useState(project.workspace_id);
  const [name, setName] = React.useState(getProjectTitle(project, t));
  const displayMove = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setOption(option !== "move" ? "move" : null);
  };
  const submitMove = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading && workspace) {
      setLoading(true);
      dispatch(actions.projects.moveProject(project, workspace)).finally(() =>
        dropdown.close()
      );
    }
  };
  const displayDeleteModal = (e: any) => {
    setOption(option !== "delete" ? "delete" : null);
  };
  const submitDelete = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      dispatch(actions.projects.deleteProject(project.id)).finally(() =>
        dropdown.close()
      );
    }
  };
  const displayRenameModal = (e: any) => {
    setOption(option !== "rename" ? "rename" : null);
  };
  const submitRename = (e: React.FormEvent) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      dispatch(actions.projects.renameProject(project.id, name)).finally(() =>
        dropdown.close()
      );
    }
  };
  return (
    <>
      <div className="dropdown-shadow"></div>
      <div
        ref={ref}
        className="dropdown-menu show"
        style={{
          zIndex: 2000,
          //   position: "absolute",
          //   backgroundColor: "red",
          padding: "12px 8px",
          minWidth: "256px",
          maxWidth: "320px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={displayMove}>
          <Move width={18} className="mr-1 rr-grey" />{" "}
          {t({ en: "Move", fr: "Déplacer" })}
        </button>
        {option === "move" && (
          <InOptionForm onSubmit={submitMove}>
            <label className="rr-t4 text-nowrap">
              {t({
                en: "Move report to workspace",
                fr: "Déplacer le rapport dans",
              })}
            </label>
            <select
              autoFocus
              className="custom-select"
              value={workspace}
              onChange={(e) => setWorkspace(e.currentTarget.value)}
            >
              {workspaces.map((w) => (
                <option key={w.id} value={w.id}>
                  {getWorkspaceName(w, t)}
                </option>
              ))}
            </select>
            <button
              className="bg-none text-primary border-0 px-0 py-0 mt-3 rr-cta"
              type="submit"
            >
              {t({ en: "Move report", fr: "Déplacer" })}
            </button>
          </InOptionForm>
        )}

        <button className="dropdown-item" onClick={displayRenameModal}>
          <Type width={18} className="mr-1 rr-grey" /> {t(appTexts.rename)}
        </button>
        {option === "rename" && (
          <InOptionForm onSubmit={submitRename}>
            <div>
              <label className="rr-t4 text-nowrap">
                {t({ en: "Change name", fr: "Changer le nom" })}
              </label>
              <input
                autoFocus
                className="form-control"
                type="text"
                value={name}
                onChange={(e) => setName(e.currentTarget.value)}
              />
              <button
                className="bg-none text-primary border-0 px-0 py-0 mt-3 rr-cta"
                type="submit"
              >
                {t(appTexts.rename)}
              </button>
            </div>
          </InOptionForm>
        )}

        <button className="dropdown-item" onClick={displayDeleteModal}>
          <Trash width={18} className="text-danger mr-1" /> {t(appTexts.delete)}
        </button>
        {option === "delete" && (
          <InOptionForm
            onSubmit={submitDelete}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(appTexts.confirmDelete)}
              </button>
            </div>
          </InOptionForm>
        )}
      </div>
    </>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
