/**
 * Page dropdown
 * 
 * This component is used to select pages from the current project and act on them.
 */

import _ from "lodash";
import React from "react";
import cls from "classnames";
import { Eye, EyeOff, FilePlus, Grid, Star, Trash } from "react-feather";
import styled from "styled-components";
import zustand from "zustand";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useDropdownSystem from "../../hooks/useDropdownSystem";
import theme from "../../theme";
import * as actions from "../../store/actions";
import useI18n from "../../hooks/useI18n";
import appTexts from "../../texts";

export interface DropDownState {
  id: "all" | string | null;
  all: boolean;
  selectedClips: Record<string, boolean>;
  setPageIds(ids: string[]): void;
  toggle(id: string): void;
  toggleAll(): void;
  close(): void;
  getSelection(): string[];
  clearSelection(): void;
}

export const usePageDropdown = zustand<DropDownState>((set, get) => ({
  id: null,
  all: false,
  selectedClips: {} as Record<string, boolean>,
  setPageIds: (pagesIds: string[]) => {
    const data = get().selectedClips;
    const selectedClips = {} as Record<string, boolean>;
    pagesIds.forEach((id) => {
      selectedClips[id] = data[id] || false;
    });
    set({ selectedClips });
  },
  toggle: (id: string) => {
    let all = get().all;
    const selectedClips = _.cloneDeep(get().selectedClips);
    selectedClips[id] = selectedClips[id] === true ? false : true;
    if (all) {
      all = !all;
    }
    set({ selectedClips, all });
    if (selectedClips[id]) {
      set({ id });
    } else {
      set({ id: null });
    }
  },
  toggleAll: () => {
    const all = !get().all;
    let id = get().id;
    let selectedClips = _.cloneDeep(get().selectedClips);
    Object.keys(selectedClips).forEach((k) => {
      selectedClips[k] = all;
    });
    if (id === "all") {
      id = null;
    } else if (all) {
      id = "all";
    }
    set({ all, selectedClips, id });
  },
  close: () => {
    set({ id: null });
  },
  getSelection: () => {
    const selectedClips = get().selectedClips;
    return Object.keys(selectedClips).filter((k) => selectedClips[k]);
  },
  clearSelection: () => {
    let selectedClips = _.cloneDeep(get().selectedClips);
    Object.keys(selectedClips).forEach((k) => {
      selectedClips[k] = false;
    });
    set({ selectedClips, id: null, all: false });
  },
}));

export const PageDropdown = () => {
  const t = useI18n();
  const dropdown = usePageDropdown();
  const ref = useDropdownSystem(dropdown.close, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [30, -30],
        },
      },
    ],
  });
  const dispatch = useDispatch();
  const page = useAppState((s) =>
    s.pages.find(
      (p) => p.id === dropdown.id || p.id === dropdown.getSelection()[0]
    )
  );
  const visible =
    page?.design_onepage ||
    page?.design_highlight ||
    page?.design_mosaic ||
    false;
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState(null as null | "delete");
  const changeOption = (v: typeof option) => (e: React.MouseEvent) => {
    e.preventDefault();
    setOption(option === v ? null : v);
  };
  const submitDelete = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const ids = dropdown.getSelection();
    dispatch(actions.pages.deletePages(ids, page?.project_id)).finally(() =>
      dropdown.close()
    );
  };

  const toggleDesign = (params: any) => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(actions.pages.setDesign(dropdown.getSelection(), params))
      .finally(() => setLoading(false))
      .finally(() => dropdown.close())
      .finally(() => dropdown.clearSelection());
  };

  const submitToggleVisibility = (e: React.FormEvent) => {
    e.preventDefault();
    toggleDesign(
      visible
        ? { highlight: false, onepage: false, mosaic: false }
        : { onepage: true }
    );
  };
  const submitToggleHighlight = (e: React.FormEvent) => {
    e.preventDefault();
    toggleDesign({
      highlight: !page?.design_highlight,
    });
  };
  const submitToggleOnepage = (e: React.FormEvent) => {
    e.preventDefault();
    toggleDesign({
      onepage: !page?.design_onepage,
    });
  };
  const submitToggleMosaic = (e: React.FormEvent) => {
    e.preventDefault();
    toggleDesign({
      mosaic: !page?.design_mosaic,
    });
  };
  return (
    <>
      {/* <div className="dropdown-shadow"></div> */}
      <div
        ref={ref}
        className="dropdown-menu show"
        style={{
          zIndex: 2000,
          //position: "absolute",
          padding: "12px 8px",
          marginLeft: "30px !important",
          minWidth: "200px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={changeOption("delete")}>
          <Trash width={18} className="text-danger mr-1" />
          {t(dropdown.all ? appTexts.deleteAll : appTexts.delete)}
        </button>
        {option === "delete" && (
          <InOptionForm
            onSubmit={submitDelete}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(appTexts.confirmDelete)}
              </button>
            </div>
          </InOptionForm>
        )}
        <button className="dropdown-item" onClick={submitToggleHighlight}>
          <Star width={18} />{" "}
          {page?.design_highlight
            ? t({ en: "Disable Highlight", fr: "Désactiver Highlight" })
            : t({ en: "Highlight", fr: "Highlight" })}
        </button>
        <button className={cls("dropdown-item")} onClick={submitToggleMosaic}>
          <Grid width={18} />{" "}
          {page?.design_mosaic
            ? t({ en: "Disable Mosaic", fr: "Désactiver Mosaïque" })
            : t({ en: "Mosaic", fr: "Mosaïque" })}
        </button>
        <button className="dropdown-item" onClick={submitToggleOnepage}>
          <FilePlus width={18} />{" "}
          {page?.design_onepage
            ? t({ en: "Disable Onepage", fr: "Désactiver page dédiée" })
            : t({ en: "Onepage", fr: "Page dédiée" })}
        </button>
        {visible && (
          <button className="dropdown-item" onClick={submitToggleVisibility}>
            {!visible ? (
              <>
                <Eye width={18} /> {t({ en: "Show", fr: "Montrer" })}
              </>
            ) : (
              <>
                <EyeOff width={18} /> {t({ en: "Hide", fr: "Cacher" })}
              </>
            )}
          </button>
        )}
      </div>
    </>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
