import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useProject } from "../hooks/useProject";
import { useAppState, useDispatch } from "../hooks/useAppState";
import { FullPage } from "../components/LoggedLayout";
import * as actions from "../store/actions";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as toasts from "../toasts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Page,
  addVersion,
  getPreviewStats,
  getProjectTitle,
  screenshotUrl,
} from "../model";
import useI18n from "../hooks/useI18n";
import appTexts from "../texts";
import { numberF } from "../utils/numberF";
import { ProjectIcon } from "../components/ui/WorkspacesTree";
import theme from "../theme";
import { DrawerState, useAppDrawer } from "../hooks/useAppDrawer";
import ClipCard, { Check } from "../components/ui/PageCard";
import { PageDropdown, usePageDropdown } from "../components/ui/PageDropdown";
import NothingBlock from "../components/ui/NothingBlock";
import styled from "styled-components";
import useSocketIo from "../hooks/useSocketIo";
import FirstClipModal from "../components/FirstClipModal";
import { toast } from "react-toastify";
import { AddClipsPanel } from "../components/ui/AddClipsPanel";

/**
 * Main component: list project's clips.
 */
export function ProjectClips(
  props: RouteComponentProps<{ id: string; pageId?: string; children: any }>
) {
  useSocketIo({ projectId: props.id! });
  const t = useI18n();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);

  // local state
  const [loading] = useProject(props.id || "");

  // global state
  const project = useAppState((s) => s.project);
  const pages = useAppState((s) => s.pages);
  const selectState = usePageDropdown();
  useEffect(() => {
    selectState.setPageIds(pages.map((p) => p.id));
  }, [pages]);
  const dispatch = useDispatch();

  // Reorganize pages.
  const handleMovePage = async (source: number, destination?: number) => {
    await dispatch(actions.pages.movePage(source, destination));
    toasts.saved();
  };

  // Sort clips
  const handleSortClips = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    await dispatch(actions.pages.sortPages(e.target.value as any));
  };

  return (
    <>
      {loading || project == null ? (
        <p>{t(appTexts.loading)}</p>
      ) : (
        <FullPage>
          {loading || !project ? (
            <></>
          ) : (
            <>
              <FirstClipModal />

              <div className="m-md-2 mt-md-4 pt-3 p-md-4">
                <div
                  className="d-flex align-items-center rr-h2 rr-dark-grey pb-4 border-bottom"
                  onClick={() =>
                    openDrawer({ project_design: project.id, focus: "title" })
                  }
                >
                  <ProjectIcon
                    src={project.logo || ""}
                    size={24}
                    className="mr-3"
                  />
                  <span>{getProjectTitle(project, t)}</span>
                </div>

                {/* Add clips panels */}
                <AddClipsPanel projectId={project.id} />

                {/* Clips listing */}
                <div className="mt-5 mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Select checkbox on the left */}
                    <div className="d-flex align-items-center">
                      {/* Select all checkbox */}
                      <div>
                        <Check
                          onClick={selectState.toggleAll}
                          checked={selectState.all}
                        />
                        {selectState.id === "all" && <PageDropdown />}
                      </div>
                      {/* resume label */}
                      <span
                        className="ml-2 rr-t2b cursor-pointer"
                        style={{ marginTop: "2px" }}
                        onClick={selectState.toggleAll}
                      >
                        {t({ en: "Clips added", fr: "Coupures ajoutées" })} (
                        {pages.length})
                      </span>
                    </div>
                    {/* Sort component on the right */}
                    <div>
                      <select
                        className="form-control"
                        onChange={handleSortClips}
                      >
                        <option>
                          {t({
                            en: "Sort clips by",
                            fr: "Trier par",
                            es: "Ordenar por",
                          })}
                        </option>
                        <option value="readers-">
                          {t({
                            en: "Most Readers First",
                            fr: "Plus Fortes Audiences en premier",
                          })}
                        </option>
                        <option value="readers+">
                          {t({
                            en: "Least Readers First",
                            fr: "Plus Faibles Audiences en premier",
                          })}
                        </option>
                        <option value="views-">
                          {t({
                            en: "Most Views First",
                            fr: "Plus Fortes Vues en premier",
                          })}
                        </option>
                        <option value="views+">
                          {t({
                            en: "Least Views First",
                            fr: "Plus Faibles Vues en premier",
                          })}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div>
                  {pages.length === 0 && (
                    <NothingBlock className="py-5">
                      🫙&nbsp;&nbsp;
                      {t({
                        en: "No clips added yet",
                        fr: "Pas encore de coupures",
                      })}
                    </NothingBlock>
                  )}
                  <DragDropContext
                    onDragStart={() => {}}
                    onDragEnd={(result) => {
                      handleMovePage(
                        result.source.index,
                        result.destination && result.destination.index
                      );
                    }}
                  >
                    <Droppable droppableId="pages">
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          style={{ paddingBottom: "200px" }}
                        >
                          {pages.map((page, i) => (
                            <div key={page.id + "-" + i}>
                              <Draggable draggableId={page.id} index={i}>
                                {(provided) => (
                                  <ClipCard
                                    key={page.id}
                                    id={page.id}
                                    kind={page.kind}
                                    draggable={provided}
                                    date={
                                      page.published_time
                                        ? new Date(page.published_time)
                                        : undefined
                                    }
                                    disabled={page.hidden || false}
                                    highlight={page.design_highlight || false}
                                    mosaic={page.design_mosaic || false}
                                    onepage={page.design_onepage || false}
                                    publicationName={page.publication_name}
                                    title={page.title}
                                    // (hidden) disabled={true}
                                    error={page.error?.code}
                                    image={addVersion(
                                      page,
                                      screenshotUrl(
                                        page.id,
                                        page.screenshot || ""
                                      )
                                    )}
                                    {...getPreviewStats(page)}
                                    open={() => {
                                      openDrawer({ clip_edit: page.id });
                                    }}
                                    loading={!page.crawled}
                                  />
                                )}
                              </Draggable>
                            </div>
                          ))}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </div>
            </>
          )}
        </FullPage>
      )}
    </>
  );
}

export function PageMiniStats({ page }: { page: Page }) {
  const s = getPreviewStats(page);
  return (
    <StatsDiv className={"d-flex mt-1 mt-md-0"}>
      <StatDiv title="Views" className="mr-1">
        <FontAwesomeIcon icon="eye" />
        <div>{s.views ? numberF(s.views, " ") : "NA"}</div>
      </StatDiv>
      <StatDiv title="Readership" className="mr-1">
        <FontAwesomeIcon icon="users" />
        <div>{s.users ? numberF(s.users, " ") : "NA"}</div>
      </StatDiv>
    </StatsDiv>
  );
}

const StatsDiv = styled.div`
  background-color: #eeeeee;
  margin-top: 15px !important;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  justify-content: center;
  @media (min-width: 768px) {
    margin: 0px !important;
    background-color: transparent;
  }
`;

const StatDiv = styled.div`
  color: ${theme.primary} !important;
  text-align: left;
  padding: 4px 15px;
  min-width: 90px;
  border-radius: 15px;
  font-size: 14px;
  margin-top: 8px;
  margin-bottom: 8px;
  div {
    display: inline-block;
    padding-left: 8px;
  }
  @media (min-width: 768px) {
    background-color: #eeeeee;
    min-width: 80px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    padding: 0px;
    padding-top: 1.25em;
    text-align: center;
    margin-bottom: 0px;
    div {
      display: block;
      padding-left: 0px;
    }
  }
`;

export function PageError({ error }: { error: Page["error"] }): JSX.Element {
  const t = useI18n();
  switch (error?.code) {
    case "BAD_URL":
      return t({
        en: <>The provided URL is not reachable</>,
        fr: <>Le lien n'est pas accessible</>,
      });
    case "SHAREDCOUNT_FAILED":
      return t({
        en: <>Access to underlying API failed (code 1)</>,
        fr: <>Erreur d'accès API interne (code 1) </>,
      });
    case "SITETRAFFIC_FAILED":
      return t({
        en: <>Access to underlying API failed (code 2)</>,
        fr: <>Erreur d'accès API interne (code 2) </>,
      });
    case "ERROR":
      return t({
        en: <>We will investigate the internal error.</>,
        fr: <>Nous avons enregistré l'erreur et allons la corriger.</>,
      });
    default:
      return error && error.code ? <>Error code: {error.code}</> : <></>;
  }
}
