import React from "react";
import { DrawerBody, DrawerTitle } from ".";
import { useAppState } from "../../hooks/useAppState";
import { State } from "../../store/types";

/**
 *
 * @deprecated Drawer not in use, see the pages/Plan.tsx file
 */
export default function PlansDrawer() {
  const team = useAppState(State.getTeam);

  return (
    <DrawerBody>
      {team?.plan === "free" ? (
        <DrawerTitle
          title="Pick a plan"
          lead="Pays For Itself with the Time You'll Save From a Single Coverage Report."
        />
      ) : (
        <DrawerTitle
          title="Pick a plan"
          lead="Pays For Itself with the Time You'll Save From a Single Coverage Report."
        />
      )}
    </DrawerBody>
  );
}
