/**
 * Clip dropdown
 *
 * This component is used to select clips from the clips vault.
 * It is used to add clips to a report or to create a new report.
 */

import _ from "lodash";
import React from "react";
import { FilePlus, Trash, Copy } from "react-feather";
import styled from "styled-components";
import zustand from "zustand";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useDropdownSystem from "../../hooks/useDropdownSystem";
import theme from "../../theme";
import * as actions from "../../store/actions";
import useI18n from "../../hooks/useI18n";
import appTexts from "../../texts";
import { getWorkspaceName, Page } from "../../model";

export interface DropDownState {
  id: "all" | string | null;
  all: boolean;
  selectedClips: Record<string, boolean>;
  setPageIds(ids: string[]): void;
  toggle(id: string): void;
  toggleAll(): void;
  close(): void;
  getSelection(): string[];
  clearSelection(): void;
}

export const useClipDropdown = zustand<DropDownState>((set, get) => ({
  id: null,
  all: false,
  selectedClips: {} as Record<string, boolean>,
  setPageIds: (pagesIds: string[]) => {
    const data = get().selectedClips;
    const selectedClips = {} as Record<string, boolean>;
    pagesIds.forEach((id) => {
      selectedClips[id] = data[id] || false;
    });
    set({ selectedClips });
  },
  toggle: (id: string) => {
    let all = get().all;
    const selectedClips = _.cloneDeep(get().selectedClips);
    selectedClips[id] = selectedClips[id] === true ? false : true;
    if (all) {
      all = !all;
    }
    set({ selectedClips, all });
    if (selectedClips[id]) {
      set({ id });
    } else {
      set({ id: null });
    }
  },
  toggleAll: () => {
    const all = !get().all;
    let id = get().id;
    let selectedClips = _.cloneDeep(get().selectedClips);
    Object.keys(selectedClips).forEach((k) => {
      selectedClips[k] = all;
    });
    if (id === "all") {
      id = null;
    } else if (all) {
      id = "all";
    }
    set({ all, selectedClips, id });
  },
  close: () => {
    set({ id: null });
  },
  getSelection: () => {
    const selectedClips = get().selectedClips;
    return Object.keys(selectedClips).filter((k) => selectedClips[k]);
  },
  clearSelection: () => {
    let selectedClips = _.cloneDeep(get().selectedClips);
    Object.keys(selectedClips).forEach((k) => {
      selectedClips[k] = false;
    });
    set({ selectedClips, id: null, all: false });
  },
}));

export const ClipDropdown = ({
  query,
}: {
  query?: {
    dateMin?: string;
    dateMax?: string;
    workspaceId?: string;
    kinds?: Page["kind"][];
  };
}) => {
  const t = useI18n();
  const dropdown = useClipDropdown();
  const ref = useDropdownSystem(dropdown.close, {
    placement: "bottom-start",
    strategy: "fixed",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [30, -30],
        },
      },
    ],
  });
  const dispatch = useDispatch();
  const page = useAppState((s) =>
    s.pages.find(
      (p) => p.id === dropdown.id || p.id === dropdown.getSelection()[0]
    )
  );
  const [loading, setLoading] = React.useState(false);
  const [option, setOption] = React.useState(
    null as null | "delete" | "add" | "new"
  );
  const changeOption = (v: typeof option) => (e: React.MouseEvent) => {
    e.preventDefault();
    setOption(option === v ? null : v);
  };
  const submitDelete = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    const ids = dropdown.getSelection();
    dispatch(actions.pages.deletePages(ids, page?.project_id)).finally(() =>
      dropdown.close()
    );
  };
  const displayAddToReport = (e: React.MouseEvent) => {
    e.preventDefault();
    setOption(option !== "add" ? "add" : null);
  };
  const submitAddToReport = async (projectId: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      await dispatch(
        query
          ? actions.vault.addQueryResultToProject(projectId, query)
          : actions.vault.addClipsToProject(projectId, dropdown.getSelection())
      );
    } finally {
      setLoading(false);
      dropdown.close();
    }
  };
  const displayNewReport = (e: React.MouseEvent) => {
    e.preventDefault();
    setOption(option !== "new" ? "new" : null);
  };
  const submitNewReport = async (title: string, workspace_id: string) => {
    if (loading) {
      return;
    }
    setLoading(true);
    try {
      const project = await dispatch(
        actions.projects.createProject({ title, workspace_id })
      );
      await dispatch(
        query
          ? actions.vault.addQueryResultToProject(project.id, query)
          : actions.vault.addClipsToProject(project.id, dropdown.getSelection())
      );
    } finally {
      setLoading(false);
      dropdown.close();
    }
  };

  return (
    <>
      {/* <div className="dropdown-shadow"></div> */}
      <div
        ref={ref}
        className="dropdown-menu show"
        style={{
          zIndex: 2000,
          //position: "absolute",
          padding: "12px 8px",
          marginLeft: "30px !important",
          minWidth: "200px",
        }}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="dropdown-item" onClick={changeOption("delete")}>
          <Trash width={18} className="text-danger mr-1" />
          {t(dropdown.all ? appTexts.deleteAll : appTexts.delete)}
        </button>
        {option === "delete" && (
          <InOptionForm
            onSubmit={submitDelete}
            onReset={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          >
            <div className="text-center text-nowrap">
              <p className="text-danger">
                {t({
                  en: "DANGER: will permanently remove clip(s) from existing reports!",
                  fr: "DANGER: va supprimer définitivement les coupures des rapports !",
                })}
              </p>
              <button className="btn btn-sm btn-danger mr-2" type="submit">
                {t(appTexts.confirmDelete)}
              </button>
            </div>
          </InOptionForm>
        )}
        <button className="dropdown-item" onClick={displayAddToReport}>
          <FilePlus width={18} className="text-muted mr-1" />
          {t({ en: "Add to report...", fr: "Ajouter à..." })}
        </button>
        {option === "add" && (
          <AddToReportForm
            onSubmit={submitAddToReport}
            onClose={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          />
        )}
        <button className="dropdown-item" onClick={displayNewReport}>
          <FilePlus width={18} className="text-muted mr-1" />
          {t({ en: "New report", fr: "Nouveau rapport" })}
        </button>
        {option === "new" && (
          <CreateReportForm
            onSubmit={submitNewReport}
            onClose={(e) => {
              e.preventDefault();
              dropdown.close();
            }}
          />
        )}
      </div>
    </>
  );
};

const AddToReportForm = ({
  onSubmit,
  onClose,
}: {
  onSubmit: (projectId: string) => void;
  onClose: (e: React.FormEvent) => void;
}) => {
  const t = useI18n();
  const workspaces = useAppState((s) => s.workspaces);
  const projects = useAppState((s) => s.projects);
  const [project, setProject] = React.useState("");
  return (
    <InOptionForm
      onSubmit={(e) => {
        e.preventDefault();
        if (project) onSubmit(project);
        else onClose(e);
      }}
      onReset={onClose}
    >
      <div>
        <label className="rr-t4 text-nowrap">
          {t({ en: "Select project", fr: "Sélectionner le projet" })}
        </label>
        <select
          autoFocus
          className="custom-select"
          value={project}
          onChange={(e) => setProject(e.currentTarget.value)}
        >
          <option value="">
            {t({ en: "Select project", fr: "Sélectionner" })}
          </option>
          {workspaces.map((w) => (
            <optgroup key={w.id} label={getWorkspaceName(w, t)}>
              {projects
                .filter((p) => p.workspace_id === w.id)
                .map((p) => (
                  <option key={p.id} value={p.id}>
                    {p.title}
                  </option>
                ))}
            </optgroup>
          ))}
        </select>
      </div>
      <div className="text-center text-nowrap mt-2">
        <button className="btn btn-sm btn-danger mr-2" type="submit">
          {t({ en: "Add to report", fr: "Ajouter au rapport" })}
        </button>
      </div>
    </InOptionForm>
  );
};

const CreateReportForm = ({
  onSubmit,
  onClose,
}: {
  onSubmit: (title: string, workspace_id: string) => void;
  onClose: (e: React.FormEvent) => void;
}) => {
  const t = useI18n();
  const workspaces = useAppState((s) => s.workspaces);
  const [workspace, setWorkspace] = React.useState("");
  const [name, setName] = React.useState("");
  return (
    <InOptionForm
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(name, workspace);
      }}
      onReset={onClose}
    >
      <div>
        <label className="rr-t4 text-nowrap">
          {t({
            en: "Select workspace",
            fr: "Sélectionner l'espace de travail",
          })}
        </label>
        <select
          autoFocus
          className="custom-select"
          value={workspace}
          onChange={(e) => setWorkspace(e.currentTarget.value)}
        >
          {workspaces.map((w) => (
            <option key={w.id} value={w.id}>
              {getWorkspaceName(w, t)}
            </option>
          ))}
        </select>
        <div>
          <label className="rr-t4 text-nowrap">
            {t({ en: "Name", fr: "Nom" })}
          </label>
          <input
            type="text"
            className="form-control"
            autoFocus
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
        </div>
        <div className="text-center text-nowrap mt-2">
          <button className="btn btn-sm btn-danger mr-2" type="submit">
            {t({ en: "Create report", fr: "Créer rapport" })}
          </button>
        </div>
      </div>
    </InOptionForm>
  );
};

const InOptionForm = styled.form`
  display: block;
  border: 1px solid ${theme.lightGrey};
  background-color: ${theme.lighterGrey};
  padding: 8px 12px;
`;
