import React, { useState } from "react";
import useI18n from "../../hooks/useI18n";
import styled from "styled-components";
import { theme } from "../../theme";
import { Link, Plus, Upload } from "react-feather";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import classNames from "classnames";
import * as toasts from "../../toasts";
import * as actions from "../../store/actions";
import { toast } from "react-toastify";
import { AddPagesModal } from "../AddPagesModal";
import { useAppDrawer } from "../../hooks/useAppDrawer";
import { DrawerState } from "../../hooks/useAppDrawer";

export function AddClipsPanel({ projectId }: { projectId?: string }) {
  const t = useI18n();
  const [addClipsModal, setAddClips] = useState(false);
  const project = useAppState((s) => s.project);
  const dispatch = useDispatch();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);

  // add clips action
  const handleAddClips = (urls: string[]) => {
    dispatch(actions.pages.addPages(projectId || null, urls))
      .then(() => {
        toast.success(
          projectId
            ? t({
                en: `Clips added at the bottom of your report, work in progress.`,
                fr: `Coupures ajoutées en bas de votre rapport, travail en cours...`,
              })
            : t({
                en: `Clips added to your vault, work in progress.`,
                fr: `Coupures ajoutées à votre bibliothèque, travail en cours...`,
              })
        );
      })
      .catch((err) => {
        toasts.error(err.toString());
      });
  };

  const handleAddBlankPage = async () => {
    const page = await dispatch(actions.pages.addBlankPage(project!.id));
    openDrawer({ clip_edit: page.id });
  };

  return (
    <div>
      <AddPagesModal
        visible={addClipsModal}
        hide={() => setAddClips(false)}
        addPages={handleAddClips}
      />
      {projectId ? (
        <>
          <div className="rr-t2b rr-dark-grey mb-2 mt-4">
            {t({ en: "Add your clips", fr: "Ajouter des coupures" })}
          </div>
          <div className="d-flex flex-column flex-lg-row">
            <div
              className="rr-bg-soft-blue p-4 d-flex flex-column justify-content-between"
              style={{ flexGrow: 1, flexBasis: 1 }}
            >
              <ul className="pl-4 mb-3 rr-t2 rr-mid-grey">
                {t({
                  en: (
                    <>
                      <li>
                        Paste links to the coverage you want to include in your
                        report.{" "}
                      </li>
                      <li>
                        ReachReport will clip the articles and automatically add
                        relevant data.{" "}
                      </li>
                    </>
                  ),
                  fr: (
                    <>
                      <li>
                        Copiez les liens que vous souhaitez inclure dans votre
                        rapport.
                      </li>
                      <li>
                        ReachReport créera des coupures pour chaque article et
                        analysera les retombées.
                      </li>
                    </>
                  ),
                })}
              </ul>
              <div>
                <button
                  className="btn btn-primary rr-cta"
                  onClick={(e) => {
                    e.preventDefault();
                    setAddClips(true);
                  }}
                >
                  <Link height={12} />{" "}
                  {t({
                    en: "Add clips by URL",
                    fr: "Ajouter des liens",
                  })}
                </button>
              </div>
            </div>
            <VSeparator />
            <UploadPdfSection />
            <VSeparator />
            <div
              className="rr-bg-softer-grey p-4 d-flex flex-column justify-content-between"
              style={{ flexGrow: 1, flexBasis: 1 }}
            >
              <p className="rr-t2 rr-mid-grey">
                {t({
                  en: "Add a single article that has appeared in print.",
                  fr: "Ajouter un article presse papier ou média traditionnel.",
                })}
              </p>
              <div>
                <button
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    handleAddBlankPage();
                  }}
                >
                  <Plus height={16} />{" "}
                  {t({
                    en: "Add print coverage",
                    fr: "Ajouter article",
                  })}
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex flex-column flex-lg-row gap-2">
          <button
            className="btn btn-sm btn-primary rr-cta"
            onClick={(e) => {
              e.preventDefault();
              setAddClips(true);
            }}
          >
            <Link height={12} />{" "}
            {t({
              en: "Add clips by URL",
              fr: "Ajouter des liens",
            })}
          </button>
          <UploadPdfSection button />
        </div>
      )}
    </div>
  );
}

function UploadPdfSection({ button }: { button?: boolean }) {
  const t = useI18n();
  const dispatch = useDispatch();
  const projectId = useAppState((s) => s.project?.id || null);
  const [drag, setDrag] = React.useState(false);
  const [uploading, setUploading] = React.useState(false);
  const onFile = async (files: FileList) => {
    if (uploading) {
      return;
    }
    try {
      setUploading(true);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (file.type !== "application/pdf") {
          continue;
        }
        await dispatch(actions.files.createFile(file))
          .then(async (res) => {
            await dispatch(actions.pages.importPage(projectId, res));
            toasts.success(
              "File uploaded, import process started for " + file.name
            );
          })
          .catch((err) => {
            console.log(err);
            toasts.error("Upload failed for file " + file.name);
          });
      }
    } finally {
      setUploading(false);
    }
  };
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) onFile(e.target.files);
  };
  const dragOn = (e: React.DragEvent) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragOff = (e: React.DragEvent) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (e: React.DragEvent) => {
    dragOff(e);
    onFile(e.dataTransfer?.files);
  };
  return (
    <>
      <input
        type="file"
        className="custom-file-input d-none"
        id="uploadPdfInput"
        disabled={uploading}
        onChange={onFileChange}
        accept=".pdf,application/pdf"
        multiple
      />
      <DropBlock
        button={button}
        onDragEnter={dragOn}
        onDragOver={dragOn}
        onDragLeave={dragOff}
        onDrop={onDrop}
        dragging={drag}
      >
        <label
          className={classNames(
            "h-100 d-flex flex-column justify-content-between",
            {
              active: drag,
              "p-0 m-0": button,
            }
          )}
          htmlFor="uploadPdfInput"
          onClick={(e) => {
            if (uploading) {
              e.preventDefault();
            }
          }}
          style={{
            flexGrow: 1,
            flexBasis: 1,
          }}
        >
          {projectId && (
            <p className="rr-t2 rr-mid-grey">
              {t({
                en: (
                  <>
                    Import a PDF file from your monitoring tool. Drag & Drop
                    file here or click to select.
                  </>
                ),
                fr: (
                  <>
                    Importer un fichier issu d'un outil de veille.
                    Glissez-déposez vos fichiers ici.
                  </>
                ),
              })}
            </p>
          )}
          <div>
            <div
              className={classNames("btn btn-secondary", {
                "w-100 m-0 btn-sm": button,
              })}
            >
              {uploading ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  ></span>{" "}
                  {t({
                    en: "Uploading, please wait...",
                    fr: "Envoi en cours, veuillez patienter...",
                  })}
                </>
              ) : (
                <>
                  <Upload />
                  {t({ en: "Import PDF", fr: "Importer des PDF" })}
                </>
              )}
            </div>
          </div>
        </label>
      </DropBlock>
    </>
  );
}

const DropBlock = styled.div.attrs<{ button?: boolean; dragging?: boolean }>(
  (props) => ({
    className:
      "rr-bg-softer-grey " +
      (props.button ? "p-0 m-0 " : "p-4 ") +
      (props.dragging ? "active " : ""),
  })
)<{ button?: boolean; dragging?: boolean }>`
  flex-grow: 1;
  flex-basis: 0;
  label.active {
    background-color: ${theme.lightGrey};
  }
  &.active {
    background-color: ${theme.lightGrey};
  }
`;

function VSeparator() {
  const t = useI18n();
  return (
    <div className="my-3 my-lg-0 px-3 d-flex flex-row flex-lg-column justify-content-center align-items-center">
      <VSeparatorLineTop />
      <div className="rr-t2 px-3 px-lg-0 rr-mid-grey">
        {t({ en: "Or", fr: "Ou" })}
      </div>
      <VSeparatorLineBottom />
    </div>
  );
}

const VSeparatorLineTop = styled.div.attrs(() => ({
  className: "flex-grow-1 mt-lg-3 mb-lg-2",
}))`
  background-color: ${theme.lightGrey};
  height: 2px;
  @media (min-width: 992px) {
    height: auto;
    width: 2px;
  }
`;

const VSeparatorLineBottom = styled.div.attrs(() => ({
  className: "flex-grow-1 mb-lg-3 mt-lg-2",
}))`
  background-color: ${theme.lightGrey};
  height: 2px;
  @media (min-width: 992px) {
    height: auto;
    width: 2px;
  }
`;
