import * as React from "react";
import { RouteComponentProps, useLocation, useNavigate } from "@reach/router";
import { FullPage } from "../components/LoggedLayout";
import { useAppState, useDispatch } from "../hooks/useAppState";
import * as actions from "../store/actions";
import Faq from "../components/Faq";
import theme from "../theme";
import classNames from "classnames";
import axios from "axios";
import useI18n from "../hooks/useI18n";
import { State } from "../store/types";
import styled from "styled-components";

const texts = {
  choose: { en: "Choose", fr: "Choisir" },
  perMonth: { en: "Per month", fr: "Par mois" },
} as const;

const PRICES = {
  eur: {
    symbolBefore: "",
    symbolAfter: "€",
    standby: 10,
    startup: 49,
    business: 99,
    agency: 249,
  },
  aed: {
    symbolBefore: "",
    symbolAfter: (
      <span className="small font-weight-light" style={{ fontSize: "66%" }}>
        د.إ
      </span>
    ),
    // <span className="small font-weight-light">AED</span>,
    standby: 50,
    startup: 200,
    business: 400,
    agency: 1000,
  },
  usd: {
    symbolBefore: "$",
    symbolAfter: "",
    standby: 15,
    startup: 59,
    business: 119,
    agency: 299,
  },
  gbp: {
    symbolBefore: "￡",
    symbolAfter: "",
    standby: 10,
    startup: 45,
    business: 90,
    agency: 220,
  },
  aud: {
    symbolBefore: "A$",
    symbolAfter: "",
    standby: 10,
    startup: 75,
    business: 150,
    agency: 399,
  },
  nzd: {
    symbolBefore: "NZ$",
    symbolAfter: "",
    standby: 10,
    startup: 99,
    business: 179,
    agency: 449,
  },
  cad: {
    symbolBefore: "CA$",
    symbolAfter: "",
    standby: 10,
    startup: 75,
    business: 149,
    agency: 349,
  },
} as const;

type Currency = keyof typeof PRICES;

export function Plans(_props: RouteComponentProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const backFromPayment = !!location.search;
  const team = useAppState(State.getTeam);
  const teamId = team?.id;
  React.useEffect(() => {
    dispatch({ type: "EXIT_PROJECT" });
    dispatch({ type: "EXIT_WORKSPACE" });
  }, [dispatch]);
  React.useEffect(() => {
    if (teamId) {
      dispatch(actions.refreshTeam(teamId));
    }
  }, [dispatch, teamId]);
  React.useEffect(() => {
    if (backFromPayment) {
      const to = setTimeout(() => {
        navigate("/plans", { replace: true });
      }, 6000);
      return () => clearTimeout(to);
    }
  }, [backFromPayment, navigate]);
  let display = backFromPayment
    ? "subscribing"
    : team && team.stripe_portal && team.plan_status !== "canceled"
    ? "manage"
    : team && team.plan === "free"
    ? "subscribe"
    : "subscribe";
  return (
    <>
      <FullPage
        noWarning
        className={display === "subscribe" ? "p-0" : undefined}
      >
        {display === "subscribing" ? (
          <SubscribingPage />
        ) : display === "manage" ? (
          <ManagePlanPage />
        ) : display === "subscribe" ? (
          <SelectPlanPage />
        ) : (
          <></>
        )}
      </FullPage>
    </>
  );
}

function Price({
  currency,
  plan,
  value,
}: {
  currency: keyof typeof PRICES;
  plan?: "startup" | "business" | "agency";
  value?: number;
}) {
  return (
    <PriceDiv>
      {PRICES[currency].symbolBefore}
      {value || (plan && PRICES[currency][plan]) || 0}
      {PRICES[currency].symbolAfter}
    </PriceDiv>
  );
}

const PriceDiv = styled.div`
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
`;

function PortalButton({
  className = "btn btn-primary",
  children,
}: {
  className?: string;
  children?: JSX.Element | string;
}) {
  const t = useI18n();
  const dispatch = useDispatch();
  return (
    <a
      href="#-"
      className={className}
      onClick={(e) => {
        e.preventDefault();
        dispatch(actions.stripe.openPortal());
      }}
    >
      {children ||
        t({
          en: <>Manage your subscription</>,
          fr: <>Gérez votre souscription</>,
        })}
    </a>
  );
}

function FaqDiv() {
  return (
    <div
      style={{
        paddingBottom: "100px",
      }}
      className="bg-light"
    >
      <Faq />
    </div>
  );
}

function SubscribingPage() {
  const t = useI18n();
  return (
    <div className="pt-5 p-4">
      <h1 className="rr-h2b">
        {t({ en: "Please wait", fr: "Merci de patienter" })}
      </h1>
      <p className="lead rr-t2 rr-mid-grey">
        {t({
          en: <>We are refreshing your subscription...</>,
          fr: <>Nous mettons à jour votre souscription...</>,
        })}
      </p>
    </div>
  );
}

function SelectPlanPage() {
  const t = useI18n();
  const dispatch = useDispatch();
  const onCheckout = (plan: string, currency: string) => {
    dispatch(actions.stripe.checkout(plan, currency));
  };
  return (
    <>
      <div>
        <div className="m-3 m-md-2 p-md-4">
          <h1 className="rr-h2b mb-3">
            {t({
              en: <>Pick a subscription plan</>,
              fr: <>Choississez votre abonnement</>,
            })}
          </h1>
          <p className="lead mt-0 mb-1 rr-t2 rr-mid-grey mb-5">
            {t({
              en: (
                <>
                  Pays For Itself with the Time You'll Save From a Single
                  Coverage Report.
                </>
              ),
              fr: (
                <>
                  Rentabilisé dès la première utilisation grâce au temps gagné
                  en générant un panorama de presse.
                </>
              ),
            })}
          </p>
          <SelectPlan onCheckout={onCheckout} />
          <FaqDiv />
        </div>
      </div>
    </>
  );
}

function SelectPlan({
  onCheckout,
}: {
  onCheckout: (plan: string, currency: string) => void;
}) {
  const t = useI18n();
  // const dispatch = useDispatch();
  const team = useAppState(State.getTeam);
  const [currency, setCurrency] = React.useState<Currency>(
    (team?.plan_currency || "eur") as any
  );
  const changeCurrency = (cur: Currency) => (e: React.MouseEvent) => {
    e.preventDefault();
    setCurrency(cur);
  };
  const checkout = (plan: string) => (e: React.MouseEvent) => {
    e.preventDefault();
    onCheckout(plan, currency);
    // dispatch(actions.stripe.checkout(plan, currency));
  };
  React.useEffect(() => {
    if (team && team.plan_currency == null) {
      axios
        .get(process.env.REACT_APP_API_URL + "/api/ipinfo")
        .then((r) => {
          if (r.data.currency) {
            setCurrency(r.data.currency);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [team]);
  return (
    <>
      <div className="card-deck">
        <PlanView
          recommended={!!team && team.plan === "agency"}
          title={t({ en: "Enterprise", fr: "Entreprise" })}
          clips={t({
            en: <>+500 clips</>,
            fr: <>+500 coupures</>,
          })}
          body={
            <p>
              {t({
                en: (
                  <>
                    We'd love to discuss your custom needs. Contact us and speak
                    directly with a founder.
                  </>
                ),
                fr: (
                  <>
                    Nous parlerons avec plaisir de vos besoins. Contactez-nous
                    et parlez directement avec un co-fondateur.
                  </>
                ),
              })}
            </p>
          }
          foot={
            <a href="mailto:hello@reachreport.io" className="btn btn-primary">
              {t({
                en: <>Contact us</>,
                fr: <>Contactez-nous</>,
              })}
            </a>
          }
        />
        <div className="w-100 d-xl-none mb-3"></div>
        <PlanView
          recommended={!!team && team.plan === "business"}
          title={t({ en: "Agency", fr: "Agence" })}
          clips={t({
            en: <>Up to 500 clips</>,
            fr: <>Jusqu'à 500 coupures</>,
          })}
          body={
            <p>
              <Price plan="agency" currency={currency} />
              <div className="rr-t3 rr-mid-grey">{t(texts.perMonth)}</div>
            </p>
          }
          foot={
            team && team.plan != "agency" ? (
              <a
                href="#-"
                className="btn btn-primary"
                onClick={checkout("agency")}
              >
                {t(texts.choose)}
              </a>
            ) : (
              <></>
            )
          }
        />
        <div className="w-100 d-xl-none mb-3"></div>
        <PlanView
          recommended={
            !team ||
            team.plan == null ||
            team.plan === "free" ||
            team.plan == "startup"
          }
          title={t({ en: "Business", fr: "Pro" })}
          clips={t({
            en: <>Up to 200 clips</>,
            fr: <>Jusqu'à 200 coupures</>,
          })}
          body={
            <p>
              <Price plan="business" currency={currency} />
              <div className="rr-t3 rr-mid-grey">{t(texts.perMonth)}</div>
            </p>
          }
          foot={
            team && team.plan != "business" ? (
              <a
                href="#-"
                className="btn btn-primary"
                onClick={checkout("business")}
              >
                {t(texts.choose)}
              </a>
            ) : (
              <></>
            )
          }
        />
        <div className="w-100 d-xl-none mb-3"></div>
        <PlanView
          title={t({ en: "Startup", fr: "Startup" })}
          clips={t({
            en: <>Up to 75 clips</>,
            fr: <>Jusqu'à 75 coupures</>,
          })}
          body={
            <p>
              <Price plan="startup" currency={currency} />
              <div className="rr-t3 rr-mid-grey">{t(texts.perMonth)}</div>
            </p>
          }
          foot={
            team && team.plan != "startup" ? (
              <a
                href="#-"
                className="btn btn-primary"
                onClick={checkout("startup")}
              >
                {t(texts.choose)}
              </a>
            ) : (
              <></>
            )
          }
        />
      </div>

      <div className="my-4 d-xl-flex justify-content-between align-items-center">
        <p>
          {t({
            en: <>All plans include unlimited reports, clients & users.</>,
            fr: (
              <>
                Toutes les formules incluent rapports, clients et utilisateurs
                <strong>illimités</strong>.
              </>
            ),
          })}
        </p>
        <p>
          <strong className="rr-t3">
            {t({ en: "Select currency:", fr: "Choisissez la devise:" })}
          </strong>{" "}
          <SwitchLink
            value="eur"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
          -{" "}
          <SwitchLink
            value="usd"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
          -{" "}
          <SwitchLink
            value="aud"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
          -{" "}
          <SwitchLink
            value="aed"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
          -{" "}
          <SwitchLink
            value="gbp"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
          -{" "}
          <SwitchLink
            value="nzd"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
          -{" "}
          <SwitchLink
            value="cad"
            currency={currency}
            changeCurrency={changeCurrency}
          />{" "}
        </p>
      </div>
    </>
  );
}

function SwitchLink({
  value,
  currency,
  changeCurrency,
}: {
  value: Currency;
  currency: Currency;
  changeCurrency: (v: Currency) => (e: React.MouseEvent) => void;
}) {
  return (
    <a
      href="#-"
      onClick={changeCurrency(value)}
      className={classNames({
        "font-weight-bold": currency === value,
      })}
    >
      {value.toUpperCase()}
    </a>
  );
}

function PlanView({
  title,
  clips,
  body,
  foot,
  recommended,
}: {
  title: any;
  clips: any;
  body: any;
  foot: any;
  recommended?: boolean;
}) {
  const t = useI18n();
  return (
    <PlanCard recommended={recommended}>
      <div className="card-body d-flex flex-column p-4">
        <div className="head">
          <h2
            className={classNames("rr-h2b", recommended ? "" : "rr-mid-grey")}
          >
            {title}
          </h2>
          <p className="sub-title rr-t2 rr-mid-grey mb-4">
            <strong className="rr-t2b rr-mid-grey">{clips}</strong>{" "}
            {t(texts.perMonth)}
          </p>
          <div className="separator"></div>
        </div>
        <div className="body flex-grow-1">{body}</div>
        <div className="foot">{foot}</div>
      </div>
    </PlanCard>
  );
}

const PlanCard = styled.div.attrs((p) => ({
  ...p,
  className: classNames(p.className, "card"),
}))<{ recommended?: boolean }>`
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.07);
  ${(p) => (p.recommended ? `border: 1px solid ${theme.blue};` : ";")}
  padding: 0px;
  margin-bottom: 24px;
  .card-body {
    padding: 16px;
  }
  @media (min-width: 960px) {
    min-height: 300px;
  }
`;

function ManagePlanPage() {
  const t = useI18n();
  const team = useAppState(State.getTeam)!;
  const dispatch = useDispatch();
  return (
    <>
      <div className="pt-5 p-4">
        <h1 className="rr-h2b mb-3">
          {t({ en: "Billing details", fr: "Détail de facturation" })}
        </h1>
        <p className="lead rr-t2 rr-mid-grey mb-2">
          {t({ en: "Current plan", fr: "Plan actuel" })}
        </p>
        <p>
          <strong className="text-capitalize">{team.plan} plan</strong>
          <br />
          {t({
            en: (
              <>
                (Used {team.clips} of {team.clips_limit} clips in the current
                billing period)
              </>
            ),
            fr: (
              <>
                (Coupures : {team.clips} sur {team.clips_limit} utilisées dans
                la période de facturation en cours)
              </>
            ),
          })}
          {team.plan_status === "active" &&
            team.plan_period_end &&
            (team.plan_cancel_at_period_end ? (
              <div>
                {t({ en: "Cancel the", fr: "Finira le" })}{" "}
                <span>{new Date(team.plan_period_end).toDateString()}</span>
              </div>
            ) : (
              <div>
                {t({ en: "Next charge", fr: "Prochain paiement" })}{" "}
                <span>{new Date(team.plan_period_end).toDateString()}</span>
              </div>
            ))}
          {team.plan_status === "active" &&
            team.plan_period_end &&
            !team.plan_cancel_at_period_end &&
            team.plan_amount && (
              <div>
                {t({ en: "Amount", fr: "Montant" })}
                <span>
                  <Price
                    currency={(team.plan_currency as any) || "eur"}
                    value={team.plan_amount / 100}
                  />
                </span>
              </div>
            )}
          {/* <PortalButton className="font-weight-bold text-underline">
            {t({ en: "Upgrade plan", fr: "Changer de plan" })}
          </PortalButton> */}
        </p>
        <PortalButton />
        <div className="mt-5 pt-3">
          <SelectPlan
            onCheckout={() => {
              dispatch(actions.stripe.openPortal());
            }}
          />
          <FaqDiv />
        </div>
      </div>
    </>
  );
}
