import * as React from "react";
import { Link, RouteComponentProps } from "@reach/router";
import { FullPage } from "../../components/LoggedLayout";
import AsyncSelect from "react-select/async";
import { api } from "../../api";
import { addVersion, Page, Project, screenshotUrl, User } from "../../model";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import { ProjectWithUser } from "../../store/types";
import { usePromise } from "../../hooks/usePromise";
import { format } from "date-fns";
import theme from "../../theme";
import { PageMiniStats } from "../ProjectClips";
import * as actions from "../../store/actions";
import { useAppDrawer } from "../../hooks/useAppDrawer";
import useConnectAsUser from "../../hooks/useConnectAsUser";
import { User as UserIcon } from "react-feather";

export function AdminDashboard(_props: RouteComponentProps) {
  return (
    <>
      <FullPage>
        <h1>Admin</h1>
        <div>
          <Link to="/admin/status">System status</Link>
        </div>
        <div className="my-4">
          <SearchUser />
        </div>
        <div className="row">
          <div className="col-md-4 py-3">
            <Projects />
          </div>
          <div className="col-md-8 py-3 ">
            <Pages />
          </div>
        </div>
      </FullPage>
    </>
  );
}

export const downloadUrl = (project: Project): string =>
  `${process.env.REACT_APP_DATA_URL}/reports/${project.id}.pdf`;

const usePageDetails = (p: Page) =>
  usePromise(async function () {
    const { data: project } = p.project_id
      ? await api.GetProject({
        projectId: p.project_id,
      })
      : { data: null };
    const { data: team } = await api.GetTeam({ teamId: p.team_id });
    return { project, team };
  });

function Screenshot({ url, selected }: { url: string; selected: boolean; }) {
  return (
    <a
      href={url}
      rel="noreferrer"
      target="_blank"
      className="bg-primary"
      style={{
        display: "inline-block",
        width: "120px",
        height: "120px",
        backgroundColor: "white",
        backgroundImage: `url('${url}')`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        border: selected ? "4px solid " + theme.accent : "4px solid white",
      }}
    >
      {" "}
    </a>
  );
}

function PageView({ page: p, onSelect }: { page: Page; onSelect: () => void; }) {
  const { data } = usePageDetails(p);
  const screenshots: string[] = (p.data as any)?.screenshots || [];
  const customScreenshot = !screenshots.find(
    (s: string) =>
      screenshotUrl(p.id, p.screenshot || "") === screenshotUrl(p.id, s)
  );
  return (
    <div className="mt-3 mb-5 card">
      <div className="card-header bg-primary text-center">
        <div className="d-flex justify-content-between">
          {screenshots.map((s: any, i: number) => (
            <Screenshot
              key={i}
              url={addVersion(p, screenshotUrl(p.id, s))}
              selected={
                screenshotUrl(p.id, p.screenshot || "") ===
                screenshotUrl(p.id, s)
              }
            />
          ))}
          {customScreenshot && (
            <Screenshot
              url={addVersion(p, screenshotUrl(p.id, p.screenshot || ""))}
              selected={true}
            />
          )}
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-8">
            <div className="text-left mb-0 mt-2 font-weight-bold">
              <span className="badge badge-primary rounded-pill mr-1">
                {p.kind}
              </span>
              {p.title}
            </div>
            <div className="text-left text-muted">
              {format(new Date(p.cdate), "PPPp")}
            </div>
            <div>
              {p.url ? (
                <a target="_blank" rel="noreferrer" href={p.url}>
                  Link to original article
                </a>
              ) : (
                <small>custom</small>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <PageMiniStats page={p} />
          </div>
        </div>
      </div>
      <div className="card-footer d-flex">
        <div className="flex-grow-1 align-self-end">
          {data && data.project && (
            <div className="rr-t2b">
              Report:
              <a href={`/projects/${data.project.id}/clips`}>
                {data.project.title || "Untitled"}
              </a>
            </div>
          )}
        </div>
        <div className="align-self-end">
          <button
            className="btn btn-primary btn-sm ml-1"
            onClick={(e) => {
              e.preventDefault();
              onSelect();
            }}
          >
            Edit clip
          </button>
        </div>
      </div>
      <div className="card-footer">
        {data && <UserMiniView user={{ id: data.team.user_id, email: data.team.name, name: data.team.name }} />}
      </div>
    </div>
  );
}

function Pages() {
  useConnectAsUser();
  const dispatch = useDispatch();
  const pages = useAppState((s) => s.pages);
  const drawers = useAppDrawer();
  const limit = 15;
  const [hasMore, setHasMore] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [, setCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const editClip = async (id: string | null) => {
    if (id == null) {
      return;
    }
    const page = pages.find((p) => p.id === id);
    if (page && page.project_id) {
      const { data: project } = await api.GetProject(page.project_id);
      dispatch({ type: "SET_PROJECT", project, });
      drawers.open({ clip_edit: page.id });
    }
  };
  React.useEffect(() => {
    setLoading(true);
    dispatch(actions.pages.adminLoadMorePages(offset, limit))
      .then((res) => {
        setCount(res.count);
        setHasMore(res.hasMore);
      })
      .finally(() => setLoading(false));
  }, [offset, dispatch]);
  return (
    <div>
      <h3>Last pages</h3>
      <div>
        {pages.map((p) => (
          <PageView page={p} key={p.id} onSelect={() => editClip(p.id)} />
        ))}
      </div>
      <LoadMoreButton
        loading={loading}
        hasMore={hasMore}
        loadMore={() => setOffset((o) => o + limit)}
        limit={limit}
      />
    </div>
  );
}

function Projects() {
  const limit = 10;
  const [hasMore, setHasMore] = React.useState(false);
  const [offset, setOffset] = React.useState(0);
  const [projects, setProjects] = React.useState([] as ProjectWithUser[]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    api
      .ListProjects({ offset, limit: limit + 1 })
      .then((res) => {
        setProjects((prjs) => prjs.concat(res.data.slice(0, limit)));
        setHasMore(res.data.length > limit);
      })
      .finally(() => setLoading(false));
  }, [offset]);
  return (
    <div>
      <h3>Projects</h3>
      <div>
        {projects.map((p) => (
          <div key={p.id} className="my-3 card">
            <div className="d-flex">
              <div
                style={{
                  width: "64px",
                  height: "64px",
                  minWidth: "64px",
                  minHeight: "64px",
                  backgroundColor: "#f0f0f0",
                  backgroundImage: `url('${p.logo}')`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                }}
              ></div>
              <div className="ml-2 px-1 pb-2">
                <h5 className="text-left mb-0 mt-2 font-weight-bold">
                  <a href={`/projects/${p.id}/clips`}>{p.title}</a>
                </h5>
                <div className="text-left text-muted">
                  {format(new Date(p.cdate), "PPPp")}
                </div>
                <div>
                  {p.print_ended ? (
                    <a href={downloadUrl(p)}>Download</a>
                  ) : (
                    <small>not printed</small>
                  )}
                </div>
              </div>
            </div>
            <div className="card-footer">
              <UserMiniView user={p.user} />
            </div>
          </div>
        ))}
        <LoadMoreButton
          loading={loading}
          hasMore={hasMore}
          loadMore={() => setOffset((o) => o + limit)}
          limit={limit}
        />
      </div>
    </div>
  );
}

function LoadMoreButton({
  loading,
  hasMore,
  loadMore,
  limit,
}: {
  loading: boolean;
  hasMore: boolean;
  loadMore: () => void;
  limit: number;
}) {
  return loading ? (
    <div className="text-center py-1">Loading...</div>
  ) : hasMore ? (
    <div className="text-center">
      <button
        className="btn btn-outline-primary"
        onClick={(e) => {
          e.preventDefault();
          loadMore();
        }}
      >
        Load {limit} more
      </button>
    </div>
  ) : (
    <div className="text-center">
      <button className="btn btn-outline-secondary" disabled>
        No more result
      </button>
    </div>
  );
}

function SearchUser() {
  const [user, setUser] = React.useState(null as User | null);
  const load = (search: string) => {
    const q = search.trim();
    const p =
      q === "" || q.length < 3
        ? Promise.resolve([] as User[])
        : api.SearchUser({ q }).then(({ data }) => Promise.resolve(data));
    return p.then((results) =>
      results.map((res) => ({
        value: res,
        label: res.email,
      }))
    );
  };
  const handleChange = (u: any) => {
    setUser(u.value);
  };
  return (
    <div className="card">
      <div className="card-body">
        <h3>Search User</h3>
        <AsyncSelect
          placeholder="Search a user"
          cacheOptions
          // value={user}
          loadOptions={load}
          onChange={handleChange}
          noOptionsMessage={() => "Pas de résultat"}
        />
        {user != null && (
          <div className="card">
            <div className="card-body">
              <UserMiniView user={user} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export function UserMiniView({ user }: { user: { id: string; email: string; name: string; }; }) {
  /* const loginAs = useConnectAsUser(); */
  const drawer = useAppDrawer();
  /* const doLogin = (e: React.MouseEvent) => {
   *   e.preventDefault();
   *   if (!user) {
   *     return;
   *   }
   *   loginAs(user.id);
   * }; */
  const doEdit = (e: React.MouseEvent) => {
    e.preventDefault();
    if (!user) {
      return;
    }
    drawer.open({ admin_user: user.id });
  };
  return (
    <div className="btn btn-primary text-white" onClick={doEdit}>
      <div className="card-body- p-2- d-flex align-items-center justify-content-start">
        <UserIcon width="16" />
        <div className="ml-2">
          <div className="rr-t3 text-white">
            <strong>{user.name}</strong>
          </div>
          <div className="small">{user.email}</div>
          <div className="mb-1 mt-n1 d-none">
            <code className="code small">#{user.id}</code>
          </div>
        </div>
      </div>
    </div>
  );
}
