import React from "react";
import {
  DrawerBody,
  DrawerSection,
  DrawerSectionBody,
  DrawerSectionFrame,
  DrawerTitle,
} from ".";
import { DrawerState, useAppDrawer } from "../../hooks/useAppDrawer";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n from "../../hooks/useI18n";
import { useLoading } from "../../hooks/useLoading";
import * as actions from "../../store/actions";
import { Design } from "../../store/types";
import texts from "../../texts";
import { saved } from "../../toasts";
import BoxImage from "../ui/BoxImage";
import NothingBlock from "../ui/NothingBlock";

export default function DesignLibraryDrawer() {
  const dispatch = useDispatch();
  const t = useI18n();
  const close = useAppDrawer((s: DrawerState) => s.close);
  const project = useAppState((s) => s.project);

  const designs = useAppState((s) => s.designs);
  const [loading, load] = useLoading();
  React.useEffect(() => {
    load(dispatch(actions.designs.loadDesigns()));
  }, [load, dispatch]);

  const handleSaveDesign = (e: React.FormEvent) => {
    e.preventDefault();
    const target = e.target as typeof e.target & {
      design_name: HTMLInputElement;
    };
    if (target)
      load(
        dispatch(
          actions.designs.create({
            name: target.design_name.value,
            logo: project?.logo || null,
            cover: project?.cover || null,
            title: project?.title || null,
            description: project?.description || null,
            summary_design: project?.summary_design || undefined,
          })
        ).then(() => {
          target.design_name.value = "";
        })
      );
  };

  const handleLoadDesign = (d: Design) => (e: React.MouseEvent) => {
    e.preventDefault();
    if (project)
      load(
        dispatch(
          actions.projects.updateProject({
            logo: d.logo || project?.logo,
            cover: d.cover || project?.cover,
            // title: project?.title || d.title,
            // description: project?.description || d.description,
            summary_design: d.summary_design || project?.summary_design,
          })
        ).then(() => {
          saved();
          close();
        })
      );
  };

  const handleDeleteDesign = (d: Design) => (e: React.MouseEvent) => {
    e.preventDefault();
    if (
      window.confirm(
        t({ en: "Delete design", fr: "Supprimer le design" }) +
          ` '${d.name} - ${d.cdate.substring(0, 10)}'?`
      )
    ) {
      load(dispatch(actions.designs.remove(d.id)));
    }
  };

  return (
    <DrawerBody>
      <DrawerTitle
        title={t({ en: "Design library", fr: "Bibliothèque de designs" })}
        lead={t({
          en: "Save your design for later reuse, or load an existing design.",
          fr: "Enregistrez vos designs pour les réutiliser dans vos nouveaux rapports.",
        })}
      />
      <DrawerSection
        title={t({
          en: "Save current design as template",
          fr: "Enregistrer le design en cours",
        })}
      >
        <form onSubmit={handleSaveDesign}>
          <div className="form-group">
            <label>{t({ en: "Name your design", fr: "Nom du design" })}</label>
            <input
              required
              disabled={loading}
              className="form-control"
              type="text"
              name="design_name"
              placeholder={t({ en: "Add name here...", fr: "Un nom..." })}
            />
          </div>
          <button
            type="submit"
            className="btn btn-primary btn-sm"
            disabled={loading}
          >
            {t({ en: "Save design", fr: "Enregistrer" })}
          </button>
        </form>
      </DrawerSection>

      <DrawerSectionFrame
        title={t({ en: "Your existing designs", fr: "Vos designs existant" })}
      >
        {designs.length === 0 ? (
          <NothingBlock>
            🫙&nbsp;&nbsp;
            {t({ en: "No designs yet", fr: "Pas encore de design" })}
          </NothingBlock>
        ) : (
          <DrawerSectionBody>
            {designs.map((d, i) => (
              <div key={d.id} className={i === 0 ? "d-flex" : "d-flex mt-3"}>
                <BoxImage
                  className="rounded"
                  src={d.cover || d.logo || ""}
                  width="150px"
                  height="110px"
                />
                <div className="ml-3">
                  <div className="mb-3 rr-t3 rr-dark-grey">{d.name}</div>
                  <div>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={handleLoadDesign(d)}
                      disabled={loading}
                    >
                      {t({ en: "Apply", fr: "Appliquer" })}
                    </button>
                    <button
                      className="ml-3 btn btn-danger btn-sm"
                      onClick={handleDeleteDesign(d)}
                      disabled={loading}
                    >
                      {t(texts.delete)}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </DrawerSectionBody>
        )}
      </DrawerSectionFrame>
    </DrawerBody>
  );
}
