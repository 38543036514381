import React, { useEffect } from "react";
import { Image, Upload, XCircle } from "react-feather";
import styled from "styled-components";
import { DrawerBody, DrawerFooter, DrawerSection, DrawerTitle } from ".";
import { DrawerState, useAppDrawer } from "../../hooks/useAppDrawer";
import { useAppState, useDispatch } from "../../hooks/useAppState";
import useI18n from "../../hooks/useI18n";
import SimpleMDE from "react-simplemde-editor";
import classNames from "classnames";
import imgResize from "../../utils/img-resize";
import * as actions from "../../store/actions";
import { useLoading } from "../../hooks/useLoading";
import MarkdownIt from "markdown-it";
import * as toasts from "../../toasts";
import { Lang, TFunction } from "../../utils/i18n";
import _ from "lodash";

const markdownIt = new MarkdownIt({
  breaks: true,
});
const markdown = (str: string) => markdownIt.render(str);

export default function ProjectDesignDrawer({
  id,
  focus,
}: {
  id: string;
  focus?: "title";
}) {
  const t = useI18n();
  const dispatch = useDispatch();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);

  const hasSummaryDesignFeat = useAppState(
    (s) => s.session?.admin || s.teams.some((t) => t.feat_summary_design)
  );
  const project = useAppState((s) => s.projects.find((p) => p.id === id));
  const [title, setTitle] = React.useState(project?.title);
  const [titleFocus, setTitleFocus] = React.useState(false);
  const titleRef = React.useRef(null as HTMLTextAreaElement | null);
  const [desc, setDesc] = React.useState(project?.description);
  const [descFocus, setDescFocus] = React.useState(false);
  const [logo, setLogo] = React.useState(project?.logo);
  const [agencyLogo, setAgencyLogo] = React.useState(project?.agency_logo);
  const [cover, setCover] = React.useState(project?.cover);
  const [coverPopup, setCoverPopup] = React.useState(false);
  const [currency, setCurrency] = React.useState(project?.currency || "$");
  const [lang, setLang] = React.useState(project?.lang || "en");
  const [summaryDesign, setSummaryDesign] = React.useState(
    project?.summary_design || "default"
  );

  React.useEffect(() => {
    setTitle(project?.title);
    setDesc(project?.description);
    setLogo(project?.logo);
    setAgencyLogo(project?.agency_logo);
    setCover(project?.cover);
    setCurrency(project?.currency || "$");
    setLang(project?.lang || "en");
    setSummaryDesign(project?.summary_design || "default");
  }, [
    project?.title,
    project?.description,
    project?.logo,
    project?.agency_logo,
    project?.cover,
    project?.currency,
    project?.lang,
    project?.summary_design,
  ]);

  React.useEffect(() => {
    if (titleRef.current) {
      const element = titleRef.current as HTMLTextAreaElement;
      element.style.height = "5px";
      element.style.height = element.scrollHeight + "px";
      if (focus === "title" && !titleFocus) {
        element.focus();
        (element as any).setSelectionRange(
          project?.title.length,
          project?.title.length
        );
        // setTimeout(() => (element as any).select?.(), 50);
      }
    }
  }, []);

  const [saving, savingLoader] = useLoading();
  const hasModification =
    project &&
    (project.title !== title ||
      project.description !== desc ||
      project.logo !== logo ||
      project.agency_logo !== agencyLogo ||
      project.cover !== cover ||
      project.lang !== lang ||
      (project.summary_design || "default") !== summaryDesign);

  const save = () => {
    savingLoader(
      dispatch(
        actions.projects.updateProject({
          logo,
          agency_logo: agencyLogo,
          cover,
          title,
          description: desc,
          currency,
          lang,
          summary_design: summaryDesign,
        })
      ).then(toasts.saved)
    );
  };

  React.useEffect(() => {
    if (descFocus) {
      const click = (e: any) => {
        setDescFocus(false);
      };
      window.addEventListener("click", click);
      return () => window.removeEventListener("click", click);
    }
  }, [descFocus]);

  return project == null ? (
    <></>
  ) : (
    <>
      <DrawerBody>
        <DrawerTitle
          title={t({
            en: <>Design your report</>,
            fr: <>Personnalisez votre rapport</>,
          })}
          lead={t({
            en: <>Let’s build a report that wows your client.</>,
            fr: <>Créez un rapport qui impressionnera votre client.</>,
          })}
        />
        <DrawerSection title={t({ en: "Create design", fr: "Votre design" })}>
          {(!logo || !cover) && (
            <div className="d-flex mb-3">
              {!logo && (
                <UploadButton id="upload-logo" onUploaded={setLogo}>
                  <Upload />{" "}
                  {t({ en: "Add client logo", fr: "Ajouter un logo" })}
                </UploadButton>
              )}
              {!cover && (
                <>
                  <button
                    className="btn btn-primary ml-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setCoverPopup(true);
                    }}
                  >
                    <Image />{" "}
                    {t({ en: "Add cover image", fr: "Image de couverture" })}
                  </button>
                  {/* <UploadButton
                    id="upload-cover"
                    className={!logo ? "ml-3" : ""}
                    onUploaded={setCover}
                  >
                    <Image />{" "}
                    {t({ en: "Add cover image", fr: "Image de couverture" })}
                  </UploadButton> */}
                </>
              )}
            </div>
          )}
          {cover && (
            <div className="mb-2">
              <ClickImage
                id="upload-cover-img"
                onClick={() => setCoverPopup(true)}
                onUploaded={setCover}
                width="100%"
                height="200px"
                url={cover}
                canDelete
              />
            </div>
          )}
          <AddCoverPopup
            onChange={(url) => {
              setCover(url);
              setCoverPopup(false);
            }}
            t={t}
            onClose={() => setCoverPopup(false)}
            open={coverPopup}
          />
          {logo && (
            <div className="mb-2">
              <UploadImage
                id="upload-logo-img"
                onUploaded={setLogo}
                width="80px"
                height="80px"
                url={logo}
                canDelete
              />
            </div>
          )}
          <div className="mt-4 mb-0">
            <NaturalInput
              ref={titleRef}
              data-value={title}
              className="rr-h1 w-100 outline-0 p-0 mb-0"
              placeholder={t({
                en: "Untitled report",
                fr: "Rapport sans titre",
                es: "Informe sin título",
              })}
              onChange={(e) => {
                setTitle(e.target.value.replace(/[\n\r\t]/g, ""));
              }}
              onBlur={(e) => {
                setTitleFocus(false);
                setTitle(e.target.value.replace(/[\n\r\t]/g, ""));
              }}
              onKeyDownCapture={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  e.preventDefault();
                  (e.target as any).blur();
                } else if (e.key === "Tab") {
                  (e.target as any).blur();
                }
              }}
              onFocus={(e) => {
                setTitleFocus(true);
              }}
              onInput={(e) => {
                const element = e.target as HTMLElement;
                element.style.height = "5px";
                element.style.height = element.scrollHeight + "px";
              }}
              value={title}
            />
          </div>
          <div>
            {descFocus ? (
              <div
                className="mde-sm"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <SimpleMDE
                  options={{
                    spellChecker:
                      navigator.language.substring(0, 2).toLowerCase() === "en",
                    maxHeight: "100px",
                    inputStyle: "contenteditable",
                  }}
                  value={desc}
                  onChange={(v) => setDesc(v.trim())}
                />
              </div>
            ) : (
              <div
                tabIndex={0}
                onClick={(e) => setDescFocus(true)}
                onFocus={(e) => setDescFocus(true)}
                className="rr-t2 mb-3"
              >
                {desc ? (
                  <div
                    className="markdown"
                    dangerouslySetInnerHTML={{
                      __html: markdown(desc),
                    }}
                  />
                ) : (
                  <div className="rr-grey">
                    {t({
                      en: (
                        <>
                          Add an introduction to your report. This is completely
                          optional.
                        </>
                      ),
                      fr: (
                        <>
                          Ajoutez une introduction à votre rapport. C'est tout à
                          fait optionnel.
                        </>
                      ),
                    })}
                  </div>
                )}
              </div>
            )}
            <div className="d-flex justify-content-end">
              {agencyLogo && (
                <div className="">
                  <UploadImage
                    id="upload-agency-logo-img"
                    onUploaded={setAgencyLogo}
                    size="contain"
                    width="60px"
                    height="60px"
                    url={agencyLogo}
                    canDelete
                  />
                </div>
              )}
              {!agencyLogo && (
                <UploadButton
                  id="upload-agency-logo"
                  onUploaded={setAgencyLogo}
                  className="btn-sm btn-info"
                >
                  <Upload /> {t({ en: "Agency logo", fr: "Logo de l'agence" })}
                </UploadButton>
              )}
            </div>
          </div>
          {hasSummaryDesignFeat && (
            <div className="">
              <div className="mt-4">
                <label htmlFor="summaryDesign" className="d-block">
                  {t({
                    en: "Summary page design:",
                    fr: "Design de la page de synthèse :",
                  })}
                </label>
                <select
                  id="summaryDesign"
                  className="form-control custom-select w-100"
                  value={summaryDesign}
                  onChange={(e) =>
                    setSummaryDesign(
                      e.target.value as "default" | "views" | "readership"
                    )
                  }
                >
                  <option value="default">
                    {t({ en: "Default", fr: "Par défaut" })}
                  </option>
                  <option value="views">
                    {t({ en: "Focus on Views", fr: "Insister sur les vues" })}
                  </option>
                  <option value="readership">
                    {t({
                      en: "Focus on Readership",
                      fr: "Insister sur le lectorat",
                    })}
                  </option>
                </select>
              </div>
            </div>
          )}
        </DrawerSection>

        <div className="mt-4">
          <button
            className="btn btn-secondary btn-sm"
            onClick={(e) => {
              e.preventDefault();
              openDrawer("design_library");
            }}
          >
            {t({ en: "Templates", fr: "Modèles" })}
          </button>
        </div>
        <div className="mt-4">
          {/* <DrawerSection title={t({ en: "Language", fr: "Language" })}> */}
          <label htmlFor="languageSelect" className="d-block">
            {t({
              en: "Select report language:",
              fr: "Choisissez la langue du rapport :",
            })}
          </label>
          <select
            id="languageSelect"
            className="form-control custom-select"
            value={lang}
            onChange={(e) => setLang(e.target.value as Lang)}
          >
            <option value="en">English</option>
            <option value="fr">Français</option>
            <option value="es">Español</option>
          </select>
          {/* </DrawerSection> */}
        </div>
        {/**
        <DrawerSection
          title={t({
            en: "Currency",
            fr: "Devise",
          })}
        >
          <div className="form-group">
            <label>
              {t({
                en: "Report's currency:",
                fr: "Devise utilisée dans le rapport :",
              })}
            </label>
            <select
              className="custom-select form-control form-control-sm"
              value={currency}
              onChange={(e) => {
                e.preventDefault();
                setCurrency(e.target.value);
              }}
            >
              {["", "$", "€", "£", "¥"].map((c, i) => (
                <option key={i} value={c}>
                  {c || t({ en: "None", fr: "Aucune" })}
                </option>
              ))}
            </select>
          </div>
        </DrawerSection>
        */}
      </DrawerBody>
      <DrawerFooter className="text-center">
        <button
          className="btn btn-primary btn-sm"
          disabled={!hasModification || saving}
          onClick={(e) => {
            e.preventDefault();
            save();
          }}
        >
          {t({ en: "Save changes", fr: "Sauver modifications" })}
        </button>
      </DrawerFooter>
    </>
  );
}

export function UploadBlock({
  id,
  className,
  children,
  onUploaded,
  style = {},
}: {
  id: string;
  className?: string;
  children: any;
  onUploaded: (url: string) => void;
  style?: React.CSSProperties;
}) {
  const [uploading, setUploading] = React.useState(false);
  const dispatch = useDispatch();
  const onFile = (files: FileList) => {
    const process = async () => {
      const file = files && files[0];
      if (file && !uploading) {
        setUploading(true);
        try {
          const blob = await imgResize(file);
          const result = await dispatch(actions.files.createFile(blob));
          onUploaded(result.url);
        } finally {
          setUploading(false);
        }
      }
    };
    process();
  };
  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.files && onFile(e.target.files);
  };
  const [drag, setDrag] = React.useState(false);
  const dragOn = (e: any) => {
    e.preventDefault();
    setDrag(true);
  };
  const dragOff = (e: any) => {
    e.preventDefault();
    setDrag(false);
  };
  const onDrop = (e: any) => {
    dragOff(e);
    onFile(e.dataTransfer?.files);
  };
  return (
    <div
      onDragEnter={dragOn}
      onDragOver={dragOn}
      onDragLeave={dragOff}
      onDrop={onDrop}
      className={drag ? "upload-block drag-over" : "upload-block"}
      style={{ position: "relative", ...style }}
    >
      <input
        type="file"
        accept="image/*"
        className="custom-file-input d-none"
        id={id}
        disabled={uploading}
        onChange={onFileChange}
      />
      <label htmlFor={id} className={className}>
        {children}
      </label>
    </div>
  );
}

export function UploadButton({
  id,
  className,
  children,
  onUploaded,
  style,
}: {
  id: string;
  className?: string;
  children: any;
  onUploaded: (url: string) => void;
  style?: React.CSSProperties;
}) {
  return (
    <UploadBlock
      style={style}
      id={id}
      className={classNames("btn btn-primary mb-0", className)}
      onUploaded={onUploaded}
    >
      {children}
    </UploadBlock>
  );
}

export function CoverImage({
  width,
  height,
  url,
  cursor,
  size = "cover",
}: {
  width: string;
  height: string;
  url: string;
  cursor?: string;
  size?: "cover" | "contain";
}) {
  return (
    <div
      style={{
        width: width,
        height: height,
        backgroundRepeat: "no-repeat",
        backgroundImage: `url('${url}')`,
        backgroundSize: size,
        backgroundPosition: "center",
        borderRadius: "6px",
        cursor,
      }}
    ></div>
  );
}

export function UploadImage({
  id,
  className,
  onUploaded,
  width,
  height,
  url,
  size,
  canDelete,
}: {
  id: string;
  className?: string;
  onUploaded: (url: string | null) => void;
  width: string;
  height: string;
  url: string;
  size?: "cover" | "contain";
  canDelete?: boolean;
}) {
  return (
    <UploadBlock
      id={id}
      className={classNames("mb-3 d-block", className)}
      onUploaded={onUploaded}
      style={{ width, height }}
    >
      <CoverImage
        size={size}
        width={width}
        height={height}
        url={url}
        cursor="pointer"
      />
      {canDelete && url && (
        <div
          className="position-absolute"
          style={{ top: "5px", right: "5px", padding: "0.25em" }}
        >
          <div
            className="bg-white text-danger rounded-circle cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              onUploaded(null);
            }}
          >
            <XCircle />
          </div>
        </div>
      )}
    </UploadBlock>
  );
}

export function ClickImage({
  id,
  className,
  onClick,
  onUploaded,
  width,
  height,
  url,
  size,
  canDelete,
}: {
  id: string;
  className?: string;
  onClick: () => void;
  onUploaded: (url: string | null) => void;
  width: string;
  height: string;
  url: string;
  size?: "cover" | "contain";
  canDelete?: boolean;
}) {
  return (
    <div
      id={id}
      className={classNames("mb-3 d-block", className)}
      onClick={onClick}
      style={{ width, height, position: "relative" }}
    >
      <CoverImage
        size={size}
        width={width}
        height={height}
        url={url}
        cursor="pointer"
      />
      {canDelete && url && (
        <div
          className="position-absolute"
          style={{ top: "5px", right: "5px", padding: "0.25em" }}
        >
          <div
            className="bg-white text-danger rounded-circle cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onUploaded(null);
            }}
          >
            <XCircle />
          </div>
        </div>
      )}
    </div>
  );
}

const NaturalInput = styled.textarea`
  width: auto;
  min-width: 1em;
  grid-area: 1 / 2;
  font: inherit;
  padding: 0.25em;
  line-height: 1em !important;
  padding-bottom: 16px !important;
  margin: 0;
  resize: none;
  background: none;
  appearance: none;
  border: none;

  &::after {
    content: attr(data-value) " ";
    visibility: hidden;
    white-space: pre-wrap;
  }
`;

function AddCoverPopup({
  onChange,
  t,
  open,
  onClose,
}: {
  onChange: (url: string) => void;
  t: TFunction;
  onClose: () => void;
  open: boolean;
}) {
  const ref = React.useRef(null as HTMLDialogElement | null);
  useEffect(() => {
    if (open) {
      ref.current?.showModal();
    } else {
      ref.current?.close();
    }
  }, [open]);
  return (
    <dialog
      ref={ref}
      className="shadow bg-white rounded p-2"
      style={{
        zIndex: 100000,
        maxWidth: "640px",
        maxHeight: "640px",
        border: "0px transparent",
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-between align-items-center my-2">
        <h2 className="h2">
          <span>
            {t({
              en: "Add a cover image",
              fr: "Ajouter une image de couverture",
            })}
          </span>
        </h2>
        <form
          className="align-self-top mt-n2"
          method="dialog"
          onSubmit={(e) => {
            e.preventDefault();
            onClose();
          }}
        >
          <button className="btn">{t({ en: "Close", fr: "Fermer" })}</button>
        </form>
      </div>
      <div className="px-3">
        <div className="row">
          {_.range(1, 7).map((i) => (
            <button
              key={i}
              className="col-4 col-md-3 btn p-0"
              onClick={(e) => {
                e.preventDefault();
                onChange(`${process.env.REACT_APP_URL}/covers/cover-${i}.jpg`);
                ref.current?.close();
              }}
            >
              <img
                className="img-fluid border-0"
                src={`${process.env.REACT_APP_URL}/covers/cover-${i}.jpg`}
              />
            </button>
          ))}
          <div className="col-4 col-md-6 p-2">
            <UploadButton
              id="upload-cover"
              className="h-100 w-100 text-center"
              onUploaded={onChange}
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              <Image />{" "}
              {t({
                en: "Upload your own cover image",
                fr: "Ajouter votre Image de couverture",
              })}
            </UploadButton>
          </div>
        </div>
      </div>
    </dialog>
  );
}
