import { ThunkAction } from "redux-thunk";
import {
  State,
  Action,
  Project,
  Page,
  PageStat,
  File as ApiFile,
  Membership,
  DesignBody,
  Design,
  Workspace,
  Feed,
  FeedItem,
} from "./types";
import { api, init as initApi } from "../api";
import Components from "../client";
import { getPreviewStats } from "../model";
import _ from "lodash";
import { success } from "../toasts";

export type Effect<R> = ThunkAction<Promise<R>, State, any, Action>;

export const init = (): Effect<void> => async (dispatch) => {
  await initApi();
  const token = window.localStorage.getItem("auth_token");
  if (token !== null) {
    await dispatch(loadSession(token));
  }
};

export const loadSession =
  (token: string): Effect<void> =>
  async (dispatch) => {
    await api
      .GetSession(token)
      .then(async ({ data: session }) => {
        dispatch({ type: "SET_SESSION", session });
        const { data: user } = await api.GetUser(session.user_id);
        const { data: teams } = await api.GetUserTeams(session.user_id);
        dispatch({ type: "SET_USER", user });
        dispatch({ type: "SET_TEAMS", teams });
        await dispatch(workspaces.loadWorkspaces());
      })
      .catch((e) => {
        console.log(e);
        if (e.response?.status === 404) {
          dispatch({ type: "SIGNOUT" });
        }
      });
  };

export const signin =
  (args: {
    login: string;
    password: string;
    lang: Components.Components.Schemas.CreateUserBody["lang"];
  }): Effect<void> =>
  async (dispatch) => {
    const { data: session } = await api.CreateSession({}, args);
    dispatch(loadSession(session.token));
  };

export const signup =
  (args: {
    lang: Components.Components.Schemas.CreateUserBody["lang"];
    name: string;
    email: string;
    password: string;
    referer_code: string;
    src_referer_url: string;
    src_landing_url: string;
  }): Effect<void> =>
  async (dispatch) => {
    await api.CreateUser({}, args);
    dispatch(
      signin({ login: args.email, password: args.password, lang: args.lang })
    );
  };

export const signout = (): Effect<void> => async (dispatch) => {
  dispatch({ type: "SIGNOUT" });
};

export const selectTeam =
  (team_id: string): Effect<void> =>
  async (dispatch, getStore) => {
    let { session } = getStore();
    const { data } = await api.SetSessionTeam(session?.token, { team_id });
    dispatch({ type: "SET_SESSION", session: data });
    await dispatch(workspaces.loadWorkspaces());
  };

export const refreshTeam =
  (team_id: string): Effect<void> =>
  async (dispatch, getStore) => {
    let { session } = getStore();
    const { data } = await api.GetUserTeams(session?.user_id);
    const team = data.find((t) => t.id === team_id);
    if (team) {
      dispatch({ type: "UPD_TEAM", team });
    }
  };

export const setLang =
  (lang: "en" | "fr" | "es"): Effect<void> =>
  async (dispatch, getStore) => {
    let { session } = getStore();
    const { data } = await api.UpdateUser(session?.user_id, { lang });
    dispatch({ type: "SET_USER", user: data });
  };

//
// TODO: logic was brute forced, clean it to avoid jump and refreshes
//
export const workspaces = {
  loadWorkspaces: (): Effect<void> => async (dispatch, getStore) => {
    const { session } = getStore();
    if (session == null) {
      return;
    }
    const { data: workspacesList } = await api.GetTeamWorkspaces({
      teamId: session.team_id,
    });
    dispatch({ type: "SET_WORKSPACES", workspaces: workspacesList });
    await dispatch(projects.loadProjects());
  },

  loadWorkspace:
    (workspaceId: string): Effect<void> =>
    async (dispatch, getStore) => {
      const { session } = getStore();
      if (!session) return;
      const { data: workspace } = await api.GetWorkspace({ workspaceId });
      dispatch({ type: "SET_WORKSPACE", workspace });
      await dispatch(projects.loadProjects());
    },

  createWorkspace:
    (args: { name: string }): Effect<Workspace> =>
    async (dispatch) => {
      const { data: workspace } = await api.CreateWorkspace({}, args);
      dispatch({ type: "ADD_WORKSPACE", workspace });
      return workspace;
    },

  deleteWorkspace:
    (workspaceId: string): Effect<void> =>
    async (dispatch) => {
      await api.DeleteWorkspace({ workspaceId });
      dispatch({ type: "DEL_WORKSPACE", id: workspaceId });
      await dispatch(workspaces.loadWorkspaces());
    },

  updateWorkspace:
    (
      id: string,
      { name, logo }: Components.Paths.UpdateWorkspace.RequestBody
    ): Effect<Workspace> =>
    async (dispatch, getStore) => {
      let { workspace, workspaces } = getStore();
      const wp = workspaces.find((w) => w.id === id);
      if (!wp) {
        throw new Error("Workspace not set");
      }
      const { data: update } = await api.UpdateWorkspace(
        { workspaceId: wp.id },
        { name, logo }
      );
      workspaces = workspaces.map((w) => (w.id === id ? update : w));
      dispatch({ type: "SET_WORKSPACES", workspaces });
      if (workspace?.id === id) {
        dispatch({ type: "SET_WORKSPACE", workspace: update });
      }
      return update;
    },
};

export const projects = {
  loadProjects: (): Effect<void> => async (dispatch, getStore) => {
    const { session } = getStore();
    if (session == null) {
      return;
    }
    const { data: projects } = await api.GetTeamProjects({
      teamId: session.team_id,
    });
    dispatch({ type: "SET_PROJECTS", projects });
  },

  loadProject:
    (projectId: string): Effect<void> =>
    async (dispatch, getStore) => {
      const { workspace } = getStore();
      const { data: project } = await api.GetProject({ projectId });
      const { data: pages } = await api.GetProjectPages({ projectId });
      await dispatch({ type: "SET_PROJECT", project, pages });
      if (!workspace || workspace.id !== project.workspace_id) {
        await dispatch(workspaces.loadWorkspace(project.workspace_id));
      }
    },

  createProject:
    (args: { title: string; workspace_id?: string }): Effect<Project> =>
    async (dispatch, getStore) => {
      let { workspace, workspaces } = getStore();
      workspace = args.workspace_id
        ? workspaces.find((w) => w.id === args.workspace_id) || null
        : workspace || workspaces[0];
      if (!workspace) {
        throw new Error("Workspace not set");
      }
      const { data: project } = await api.CreateProject(
        {},
        { ...args, workspace_id: workspace.id }
      );
      dispatch({ type: "ADD_PROJECT", project });
      return project;
    },

  deleteProject:
    (projectId: string): Effect<void> =>
    async (dispatch) => {
      await api.DeleteProject({ projectId });
      dispatch({ type: "DEL_PROJECT", id: projectId });
    },

  printProject:
    (projectId: string): Effect<void> =>
    async (dispatch) => {
      const { data: project } = await api.PrintProject({ projectId });
      dispatch({ type: "SET_PROJECT", project, printing: true });
    },

  refreshPrinting:
    (projectId: string): Effect<Project> =>
    async (dispatch) => {
      const { data: project } = await api.GetProject({ projectId });
      if (project.print_ended) {
        dispatch({ type: "SET_PROJECT", project });
      }
      return project;
    },

  renameProject:
    (projectId: string, title: string): Effect<void> =>
    async (dispatch, getStore) => {
      const { projects } = getStore();
      let project = projects.find((p: any) => p.id === projectId);
      if (!project) {
        console.log("error: project not in store");
        return;
      }
      const { data: update } = await api.UpdateProject(
        { projectId: project.id },
        { title }
      );
      dispatch({ type: "UPD_PROJECT", project: update });
    },

  setProjectLogo:
    (logo: string | null): Effect<void> =>
    async (dispatch, getStore) => {
      const { project } = getStore();
      if (!project) {
        return;
      }
      const { data: update } = await api.UpdateProject(
        { projectId: project.id },
        { logo }
      );
      dispatch({ type: "SET_PROJECT", project: update });
    },

  exitProject: (): Effect<void> => async (dispatch, getStore) => {
    const { project } = getStore();
    if (!project) {
      return;
    }
    await dispatch({ type: "EXIT_PROJECT" });
  },

  moveProject:
    (p: Project, w: string): Effect<void> =>
    async (dispatch) => {
      const { data: project } = await api.MoveProject(
        { projectId: p.id },
        { workspace_id: w }
      );
      dispatch({ type: "UPD_PROJECT", project });
    },

  updateProject:
    ({
      title,
      logo,
      agency_logo,
      cover,
      description,
      currency,
      lang,
      summary_design,
    }: Components.Paths.UpdateProject.RequestBody): Effect<Project> =>
    async (dispatch, getStore) => {
      const { project } = getStore();
      if (!project) {
        throw new Error("Project not set");
      }
      const { data: update } = await api.UpdateProject(
        { projectId: project.id },
        {
          title,
          logo,
          agency_logo,
          cover,
          description,
          currency,
          lang,
          summary_design,
        }
      );
      dispatch({ type: "UPD_PROJECT", project: update });
      return update;
    },
};

export const shares = {
  loadShares:
    (projectId: string): Effect<void> =>
    async (dispatch) => {
      const { data: shares } = await api.GetProjectShares({ projectId });
      dispatch({ type: "SET_SHARES", shares });
    },

  addShare:
    (project_id: string, email: string): Effect<void> =>
    async (dispatch) => {
      const { data: share } = await api.ShareProjectWith(
        {},
        { project_id, email }
      );
      dispatch({ type: "ADD_SHARE", share });
    },

  delShare:
    (shareId: string): Effect<void> =>
    async (dispatch) => {
      await api.DeleteShare({ shareId });
      dispatch({ type: "DEL_SHARE", id: shareId });
    },

  resendInvitation:
    (shareId: string): Effect<void> =>
    async (dispatch) => {
      await api.ResendShareInvitation({ shareId }, {});
    },
};

export const pages = {
  addPages:
    (
      projectId: string | null,
      urls: string[],
      afterFailure = false
    ): Effect<void> =>
    async (dispatch) => {
      for (let url of urls) {
        const { data: newPage } = await api.CreatePage(
          {},
          { project_id: projectId, url, afterFailure }
        );
        if (newPage) {
          dispatch({ type: "ADD_PAGE", page: newPage });
        }
      }
    },

  importPage:
    (projectId: string | null, file: ApiFile): Effect<Page> =>
    async (dispatch) => {
      const { data: newPage } = await api.ImportPage(
        {},
        { project_id: projectId, file_id: file.id }
      );
      await dispatch({ type: "ADD_PAGE", page: newPage });
      return newPage;
    },

  addBlankPage:
    (projectId: string, at_index?: number): Effect<Page> =>
    async (dispatch, getStore) => {
      const pages = getStore().pages;
      const { data: newPage } = await api.CreateBlankPage(
        {},
        { project_id: projectId, at_index: at_index ?? pages.length }
      );
      await dispatch({ type: "ADD_PAGE", page: newPage });
      return newPage;
    },

  selectIntroduction: (): Effect<void> => async (dispatch) => {
    dispatch({ type: "SET_PAGE", page: null });
  },

  selectPage:
    (id: string): Effect<void> =>
    async (dispatch, getStore) => {
      const store = getStore();
      const page = store.pages.find((p) => p.id === id);
      if (page !== undefined) {
        dispatch({ type: "SET_PAGE", page });
      } else {
        console.log("page", id, "not found");
      }
    },

  movePage:
    (source: number, destination?: number): Effect<void> =>
    async (dispatch, getStore) => {
      if (destination === undefined) {
        return;
      }
      if (source === destination) {
        return;
      }
      const { pages, project } = getStore();
      const moved = pages[source];
      const newPages = pages.concat();
      newPages.splice(source, 1);
      newPages.splice(destination, 0, moved);
      const ids = newPages.map((p: Page) => p.id);
      // optimist request
      api
        .SetProjectPagesOrder({ projectId: project!.id }, { ids })
        .then((res) => {})
        .catch((err) => console.log(err));
      dispatch({
        type: "SET_PAGES",
        pages: newPages.map((p: Page, i: number) => ({
          ...p,
          pos: i,
          udate: new Date().toISOString(),
        })),
      });
    },

  refreshCrawling: (): Effect<void> => async (dispatch, getStore) => {
    const { pages, refreshing } = getStore();
    if (refreshing) {
      return;
    }
    dispatch({ type: "SET_REFRESHING", value: true });
    try {
      const crawling = pages.filter((p: Page) => p.crawled === false);
      const updated = [];
      for (const p of crawling) {
        const { id: pageId } = p;
        const { data: page } = p.project_id
          ? await api.GetProjectPage({
              projectId: p.project_id,
              pageId,
            })
          : await api.GetPage({ pageId });
        updated.push(page);
      }
      const readyPages = updated.filter((p) => p.crawled);
      if (readyPages.length > 0) {
        const newPages = pages.map((p: Page) => {
          const readyPage = readyPages.find((r) => r.id === p.id);
          return readyPage || p;
        });
        dispatch({ type: "SET_PAGES", pages: newPages });
      }
    } finally {
      dispatch({ type: "SET_REFRESHING", value: false });
    }
  },

  refreshPage:
    (pageId: string): Effect<void> =>
    async (dispatch, getStore) => {
      const { pages, project } = getStore();
      const old = pages.find((p: Page) => p.id === pageId);
      const { data: page } = await (old?.project_id || project
        ? api.GetProjectPage({
            pageId,
            projectId: old?.project_id || project!.id,
          })
        : api.GetPage({ pageId })
      ).catch((err) =>
        err.response.status === 404 ? { data: null } : Promise.reject(err)
      );
      if (!page) {
        dispatch({ type: "DEL_PAGE", pageId });
        return;
      }
      if (page && !old) {
        dispatch({ type: "ADD_PAGE", page });
      } else {
        dispatch({ type: "UPD_PAGE", page });
      }
    },

  crawlPage:
    (pageId: string, projectId?: string | null): Effect<void> =>
    async (dispatch) => {
      if (projectId) {
        const { data: page } = await api.CrawlProjectPage(
          { pageId, projectId },
          {}
        );
        dispatch({ type: "UPD_PAGE", page });
      } else {
        const { data: page } = await api.CrawlPage({ pageId });
        dispatch({ type: "UPD_PAGE", page });
      }
    },

  updatePage:
    (
      pageId: string,
      projectId: string | null,
      data: Components.Paths.UpdatePage.RequestBody
    ): Effect<Page> =>
    async (dispatch) => {
      if (projectId) {
        const { data: page } = await api.UpdateProjectPage(
          { pageId, projectId },
          data
        );
        dispatch({ type: "UPD_PAGE", page });
        return page;
      }
      const { data: page } = await api.UpdatePage({ pageId }, data);
      dispatch({ type: "UPD_PAGE", page });
      return page;
    },

  deletePage:
    (pageId: string, projectId?: string | null): Effect<void> =>
    async (dispatch) => {
      if (projectId) {
        await api.RemoveFromProject({ pageId, projectId });
      } else {
        await api.DeletePage({ pageId });
      }
      dispatch({ type: "DEL_PAGE", pageId });
    },

  deletePages:
    (pageIds: string[], projectId?: string | null): Effect<void> =>
    async (dispatch) => {
      for (let pageId of pageIds) {
        if (projectId) {
          await api.RemoveFromProject({ pageId, projectId });
        } else {
          await api.DeletePage({ pageId });
        }
      }
      for (let pageId of pageIds) {
        dispatch({ type: "DEL_PAGE", pageId });
      }
    },

  updatePageStat:
    (
      pageId: string,
      data: Components.Paths.UpdatePageStat.RequestBody
    ): Effect<PageStat[]> =>
    async (dispatch) => {
      const { data: stats } = await api.UpdatePageStat(
        { pageId, statId: data.key },
        data
      );
      dispatch({ type: "UPD_PAGE_STATS", pageId, stats });
      return stats;
    },

  adminLoadMorePages:
    (
      offset: number,
      limit: number
    ): Effect<{ hasMore: boolean; count: number; slice: Page[] }> =>
    async (dispatch, getStore) => {
      const { data } = await api.AdminListPages({ offset, limit: limit + 1 });
      const slice = getStore().pages.concat(data.pages.slice(0, limit));
      dispatch({
        type: "SET_PAGES",
        pages: slice,
      });
      return {
        hasMore: data.pages.length > limit,
        count: data.count,
        slice: slice,
      };
    },

  setDesign:
    (
      ids: string[],
      design: {
        highlight?: boolean;
        mosaic?: boolean;
        onepage?: boolean;
      }
    ): Effect<void> =>
    async (dispatch, getStore) => {
      const project = getStore().project;
      if (!project) {
        return;
      }
      const { data } = await api.ChangePagesDesign(
        { projectId: project.id },
        { ids, ...design }
      );
      const pages = getStore().pages.map((p) => {
        if (data.ids.includes(p.id)) {
          return {
            ...p,
            design_highlight:
              design.highlight === undefined
                ? p.design_highlight
                : design.highlight,
            design_mosaic:
              design.mosaic === undefined ? p.design_mosaic : design.mosaic,
            design_onepage:
              design.onepage === undefined ? p.design_onepage : design.onepage,
          };
        }
        return p;
      });
      dispatch({ type: "SET_PAGES", pages });
    },

  sortPages:
    (order: "views+" | "views-" | "readers+" | "readers-"): Effect<void> =>
    async (dispatch, getStore) => {
      if (!order) {
        return;
      }
      const { pages, project } = getStore();
      const newPages = _.orderBy(
        pages.map((p) => {
          const stats = getPreviewStats(p);
          return { ...p, ...stats };
        }),
        order.startsWith("views") ? "views" : "users",
        order.endsWith("-") ? "desc" : "asc"
      );
      api.SetProjectPagesOrder(
        { projectId: project!.id },
        { ids: newPages.map((p) => p.id) }
      );
      dispatch({
        type: "SET_PAGES",
        pages: newPages.map((p, i) => ({
          ...p,
          pos: i,
          udate: new Date().toISOString(),
        })),
      });
    },
};

export const vault = {
  loadClips:
    ({
      dateMin,
      dateMax,
      workspaceId,
      kinds,
      offset,
      limit,
    }: {
      dateMin?: string;
      dateMax?: string;
      workspaceId?: string;
      kinds?: Page["kind"][];
      offset?: number;
      limit?: number;
    }): Effect<void> =>
    async (dispatch) => {
      const { data } = await api.ListClips({
        dateMin,
        dateMax,
        workspaceId,
        kinds,
        offset: offset || 0,
        limit: limit || 1000,
      });
      dispatch({
        type: "SET_PAGES",
        pages: data.pages,
        totalPagesCount: data.count,
      });
    },

  addClipsToProject:
    (projectId: string, clipIds: string[]): Effect<void> =>
    async () => {
      await api.AddClipsToProject({ projectId }, { clips_ids: clipIds });
    },

  addQueryResultToProject:
    (
      projectId: string,
      {
        dateMin,
        dateMax,
        workspaceId,
        kinds,
      }: {
        dateMin?: string;
        dateMax?: string;
        workspaceId?: string;
        kinds?: Page["kind"][];
      }
    ): Effect<void> =>
    async () => {
      await api.AddClipsToProjectFromQuery(
        { projectId },
        {
          dateMin,
          dateMax,
          workspaceId,
          kinds,
        }
      );
      success("Clips added to project");
    },
};

export const files = {
  createFile:
    (file: File, onUploadProgress?: (e: any) => void): Effect<ApiFile> =>
    async (_dispatch, getStore) => {
      const { session } = getStore();
      if (!session?.team_id) {
        throw new Error("Team not selected");
      }
      const { project } = getStore();
      const data = new FormData();
      data.set("file", file, file.name);
      if (project) {
        const fn =
          file.type === "application/pdf" ? api.UploadPdfFile : api.UploadFile;
        const { data: result } = await fn(
          { projectId: project.id },
          data,
          onUploadProgress && { onUploadProgress }
        );
        return result;
      }
      const fn =
        file.type === "application/pdf"
          ? api.UploadTeamPdfFile
          : api.UploadTeamFile;
      const { data: result } = await fn(
        { teamId: session.team_id },
        data,
        onUploadProgress && { onUploadProgress }
      );
      return result;
    },

  createTeamFile:
    (file: File): Effect<ApiFile> =>
    async (_dispatch, getStore) => {
      const { session } = getStore();
      if (!session?.team_id) {
        throw new Error("Team not selected");
      }
      const data = new FormData();
      data.set("file", file, file.name);
      const { data: result } = await api.UploadTeamFile(
        { teamId: session.team_id },
        data
      );
      return result;
    },
};

export const memberships = {
  list: (): Effect<void> => async (dispatch) => {
    await dispatch({ type: "SET_MEMBERSHIPS", memberships: [] });
    const { data: memberships } = await api.ListMemberships({});
    console.log(memberships);
    await dispatch({ type: "SET_MEMBERSHIPS", memberships });
  },
  create:
    ({
      email,
      role,
    }: {
      email: string;
      role: Membership["role"];
    }): Effect<void> =>
    async (dispatch) => {
      const { data: membership } = await api.CreateMembership(
        {},
        { email, role }
      );
      await dispatch({ type: "ADD_MEMBERSHIP", membership });
    },
  remove:
    (userId: string): Effect<void> =>
    async (dispatch) => {
      await api.DeleteMembership({ userId });
      await dispatch({ type: "DEL_MEMBERSHIP", user_id: userId });
    },
  update:
    (m: Membership, { role }: { role: Membership["role"] }): Effect<void> =>
    async (dispatch) => {
      const { data: membership } = await api.UpdateMembership(
        { userId: m.user_id },
        { role }
      );
      await dispatch({ type: "UPD_MEMBERSHIP", membership });
    },
};

export const stripe = {
  checkout:
    (plan: string, currency: string): Effect<void> =>
    async (_dispatch) => {
      const { data: url } = await api.Checkout({ plan, currency });
      window.location.assign(url);
    },

  openPortal: (): Effect<void> => async (_dispatch) => {
    const { data: url } = await api.Portal({});
    window.location.assign(url);
  },
};

export const designs = {
  loadDesigns: (): Effect<void> => async (dispatch, getStore) => {
    const { session } = getStore();
    if (session == null) {
      return;
    }
    await dispatch({ type: "SET_DESIGNS", designs: [] });
    const { data: designs } = await api.GetTeamDesigns({
      teamId: session.team_id,
    });
    await dispatch({ type: "SET_DESIGNS", designs });
  },
  create:
    (data: DesignBody): Effect<void> =>
    async (dispatch) => {
      const { data: design } = await api.CreateDesign({}, data);
      await dispatch({ type: "ADD_DESIGN", design });
    },
  update:
    (d: Design, data: DesignBody): Effect<void> =>
    async (dispatch) => {
      const { data: design } = await api.UpdateDesign({ designId: d.id }, data);
      await dispatch({ type: "UPD_DESIGN", design });
    },
  remove:
    (designId: string): Effect<void> =>
    async (dispatch) => {
      await api.DeleteDesign({ designId });
      await dispatch({ type: "DEL_DESIGN", design_id: designId });
    },
};

export const teams = {
  setCfcEnabled:
    (b: boolean): Effect<void> =>
    async (dispatch, getStore) => {
      const { session } = getStore();
      if (session == null) {
        return;
      }
      const { data: team } = await api.UpdateTeam(
        { teamId: session.team_id },
        { cfc_enabled: b }
      );
      dispatch({ type: "UPD_TEAM", team });
    },
  setTimezone:
    (tz: string): Effect<void> =>
    async (dispatch, getStore) => {
      const { session } = getStore();
      if (session == null) {
        return;
      }
      const { data: team } = await api.UpdateTeamTimezone(
        { teamId: session.team_id },
        { timezone: tz }
      );
      dispatch({ type: "UPD_TEAM", team });
    },
};

export const feeds = {
  listFeeds:
    (workspaceId: string): Effect<Feed[]> =>
    async (dispatch, getStore) => {
      const { session } = getStore();
      if (session == null) {
        return [];
      }
      const {
        data: { feeds },
      } = await api.ListFeeds({
        workspaceId,
      });
      dispatch({ type: "SET_FEEDS", feeds });
      const {
        data: { items, offset, limit },
      } = await api.ListWorkspaceFeedsItems({
        workspaceId,
        offset: 0,
        limit: 1000,
      });
      dispatch({ type: "SET_FEED_ITEMS", items, offset, limit });
      return feeds;
    },

  refreshFeed:
    (feedId: string): Effect<Feed> =>
    async (dispatch) => {
      const { data: feed } = await api.GetFeed({ feedId });
      await dispatch({ type: "ADD_FEED", feed });
      const {
        data: { items, offset, limit },
      } = await api.ListWorkspaceFeedsItems({
        workspaceId: feed.workspace_id,
        offset: 0,
        limit: 1000,
      });
      dispatch({ type: "SET_FEED_ITEMS", items, offset, limit });
      return feed;
    },

  createFeed:
    (workspaceId: string, url: string): Effect<Feed> =>
    async (dispatch) => {
      const { data: feed } = await api.CreateFeed({}, { workspaceId, url });
      dispatch({ type: "ADD_FEED", feed });
      return feed;
    },

  deleteFeed:
    (feedId: string): Effect<void> =>
    async (dispatch) => {
      await api.DeleteFeed({ feedId });
      dispatch({ type: "DEL_FEED", feed_id: feedId });
    },

  updateItem:
    (
      itemId: string,
      data: Components.Paths.UpdateFeedItem.RequestBody
    ): Effect<void> =>
    async (dispatch) => {
      const { data: item } = await api.UpdateFeedItem({ id: itemId }, data);
      dispatch({
        type: "UPD_FEED_ITEM",
        item,
      });
    },

  publishItems:
    (itemIds: string[], projectId: string): Effect<void> =>
    async (dispatch, getStore) => {
      let { feedItems: items } = getStore();
      for (let itemId of itemIds) {
        const { data: item } = await api.PublishItemToProject(
          { id: itemId },
          { projectId }
        );
        dispatch({
          type: "UPD_FEED_ITEM",
          item,
        });
      }
    },

  publishItem:
    (itemId: string, projectId: string): Effect<void> =>
    async (dispatch, getStore) => {
      const { data: item } = await api.PublishItemToProject(
        { id: itemId },
        { projectId }
      );
      let { feedItems: items } = getStore();
      items = items.map((i) => (i.id === itemId ? item : i));
      dispatch({
        type: "SET_FEED_ITEMS",
        items,
        offset: 0,
        limit: items.length,
      });
    },

  deleteItem:
    (itemId: string): Effect<void> =>
    async (dispatch) => {
      await api.ArchiveFeedItem({ id: itemId });
      dispatch({ type: "DEL_FEED_ITEM", id: itemId });
    },

  archiveItems:
    (itemIds: string[], states: FeedItem["state"][] = []): Effect<void> =>
    async (dispatch, getStore) => {
      const items = getStore().feedItems;
      for (const itemId of itemIds) {
        const item = items.find((i) => i.id === itemId);
        if (!item || (states.length > 0 && !states.includes(item.state))) {
          continue;
        }
        await api.ArchiveFeedItem({ id: itemId });
        dispatch({ type: "DEL_FEED_ITEM", id: itemId });
      }
    },

  refreshNotifications: (): Effect<void> => async (dispatch) => {
    const { data: notifications } = await api.ListWorkspaceFeedCounters({});
    dispatch({
      type: "SET_NOTIFICATIONS",
      notifications: notifications.reduce(
        (acc, n) => ({ ...acc, [n.workspace_id]: n.notifications }),
        {}
      ),
    });
  },
};
