import React, { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useAppState, useDispatch } from "../hooks/useAppState";
import { FullPage } from "../components/LoggedLayout";
import {
  addVersion,
  getPreviewStats,
  getWorkspaceName,
  Page,
  screenshotUrl,
} from "../model";
import useI18n from "../hooks/useI18n";
import { DrawerState, useAppDrawer } from "../hooks/useAppDrawer";
import NothingBlock from "../components/ui/NothingBlock";
import styled from "styled-components";
import ClipCardVault, { Check } from "../components/ui/ClipCardVault";
import * as actions from "../store/actions";
import { useLoading } from "../hooks/useLoading";
import appTexts from "../texts";
import { ClipDropdown, useClipDropdown } from "../components/ui/ClipDropdown";
import Select from "react-select";
import useSocketIo from "../hooks/useSocketIo";
import { AddClipsPanel } from "../components/ui/AddClipsPanel";

const GridContainer = styled.div`
  gap: 20px;
  margin-top: 20px;
`;

// Add this type and reducer before the ClipsVault component
type FiltersState = {
  workspaceId: string;
  dateMin: string;
  dateMax: string;
  kinds: Page["kind"][];
  offset: number;
  limit: number;
};

type FiltersAction =
  | { type: "SET_WORKSPACE_ID"; payload: string }
  | { type: "SET_DATE_MIN"; payload: string }
  | { type: "SET_DATE_MAX"; payload: string }
  | { type: "SET_KINDS"; payload: Page["kind"][] }
  | { type: "SET_OFFSET"; payload: number }
  | { type: "SET_LIMIT"; payload: number }
  | { type: "RESET_FILTERS" };

const initialFiltersState: FiltersState = {
  workspaceId: "",
  dateMin: "",
  dateMax: "",
  kinds: [],
  offset: 0,
  limit: 10,
};

function filtersReducer(
  state: FiltersState,
  action: FiltersAction
): FiltersState {
  switch (action.type) {
    case "SET_WORKSPACE_ID":
      return { ...state, workspaceId: action.payload, offset: 0 };
    case "SET_DATE_MIN":
      return { ...state, dateMin: action.payload, offset: 0 };
    case "SET_DATE_MAX":
      return { ...state, dateMax: action.payload, offset: 0 };
    case "SET_KINDS":
      return { ...state, kinds: action.payload, offset: 0 };
    case "SET_OFFSET":
      return { ...state, offset: action.payload };
    case "SET_LIMIT":
      return { ...state, limit: action.payload, offset: 0 };
    case "RESET_FILTERS":
      return initialFiltersState;
    default:
      return state;
  }
}

/**
 * ClipsLibrary component: displays all available clips in a grid layout
 */
export function ClipsVault(_props: RouteComponentProps) {
  const t = useI18n();
  const team = useAppState((s) => s.session?.team_id);
  useSocketIo({ teamId: team! });
  const workspaces = useAppState((s) => s.workspaces);
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);
  const totalPagesCount = useAppState((s) => s.totalPagesCount);
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const [filters, filtersDispatch] = React.useReducer(
    filtersReducer,
    initialFiltersState
  );

  useEffect(() => {
    dispatch(actions.projects.exitProject());
  }, [dispatch]);

  useEffect(() => {
    load(
      dispatch(
        actions.vault.loadClips({
          dateMin: filters.dateMin || undefined,
          dateMax: filters.dateMax || undefined,
          workspaceId: filters.workspaceId || undefined,
          kinds: filters.kinds?.length > 0 ? filters.kinds : undefined,
          offset: filters.offset,
          limit: filters.limit,
        })
      )
    );
  }, [dispatch, load, filters]);
  // true if there is at least one filter active
  const hasFilters =
    filters.workspaceId ||
    filters.dateMin ||
    filters.dateMax ||
    filters.kinds.length > 0;
  // global state
  const pages = useAppState((s) => s.pages);
  const selectState = useClipDropdown();

  useEffect(() => {
    selectState.setPageIds(pages.map((p) => p.id));
  }, [pages]);

  return (
    <FullPage>
      <div className="m-md-2 mt-md-4 pt-3 p-md-4">
        <div className="d-flex justify-content-between align-items-center rr-h2 rr-dark-grey pb-4 border-bottom">
          <div>{t({ en: "Clips vault", fr: "Bibliothèque de coupures" })}</div>
          <AddClipsPanel />
        </div>
        {/* filters */}
        <div className="d-flex justify-content-between align-items-center px-3 py-2 gap-2 bg-light">
          <div className="px-2 text-muted">
            {t({ en: "Filters:", fr: "Filtres:" })}
          </div>
          <Select
            isDisabled={loading}
            className="w-100"
            options={[
              { label: "All workspaces", value: "" },
              ...workspaces.map((w) => ({
                label: getWorkspaceName(w, t),
                value: w.id,
              })),
            ]}
            defaultValue={{
              label: "All workspaces",
              value: "",
            }}
            onChange={(e) => {
              filtersDispatch({
                type: "SET_WORKSPACE_ID",
                payload: e?.value || "",
              });
            }}
          />
          <Select
            isDisabled={loading}
            isMulti
            className="w-100"
            placeholder="All kinds"
            options={[
              { label: "Press", value: "press" },
              { label: "Event", value: "event" },
              { label: "Radio", value: "radio" },
              { label: "TV", value: "tv" },
              { label: "Podcast", value: "podcast" },
              { label: "YouTube", value: "youtube" },
              { label: "Web", value: "web" },
              { label: "Twitter", value: "twitter" },
              { label: "LinkedIn", value: "linkedin" },
              { label: "Instagram", value: "instagram" },
              { label: "Facebook", value: "facebook" },
              { label: "Pinterest", value: "pinterest" },
              { label: "TikTok", value: "tiktok" },
              { label: "Custom", value: "custom" },
            ]}
            onChange={(e) => {
              filtersDispatch({
                type: "SET_KINDS",
                payload: Array.from(e?.values() || []).map(
                  (v) => v.value as Page["kind"]
                ),
              });
            }}
            value={filters.kinds.map((k) => ({ label: k, value: k }))}
          />
          <div className="input-group">
            <input
              disabled={loading}
              type="date"
              className="form-control"
              style={{
                borderTopRightRadius: "0px !important",
                borderBottomRightRadius: "0px !important",
                width: "50px",
              }}
              value={filters.dateMin}
              onChange={(e) =>
                filtersDispatch({
                  type: "SET_DATE_MIN",
                  payload: e.target.value,
                })
              }
              max={filters.dateMax}
            />
            <input
              disabled={loading}
              type="date"
              className="form-control"
              style={{
                borderTopLeftRadius: "0px !important",
                borderBottomLeftRadius: "0px !important",
                width: "50px",
              }}
              value={filters.dateMax}
              onChange={(e) =>
                filtersDispatch({
                  type: "SET_DATE_MAX",
                  payload: e.target.value,
                })
              }
              min={filters.dateMin}
            />
          </div>
          <div>
            <button
              disabled={loading}
              className="btn btn-outline-primary border border-primary"
              onClick={(e) => {
                e.preventDefault();
                filtersDispatch({ type: "RESET_FILTERS" });
              }}
            >
              {t({ en: "Clear", fr: "RAZ" })}
            </button>
          </div>
        </div>
        {/* Select checkbox on the left */}
        <div className="d-flex align-items-center mt-4 mb-2">
          {/* Select all checkbox */}
          <div>
            <Check onClick={selectState.toggleAll} checked={selectState.all} />
            {selectState.id === "all" && (
              <ClipDropdown
                query={{
                  dateMin: filters.dateMin,
                  dateMax: filters.dateMax,
                  workspaceId: filters.workspaceId,
                  kinds: filters.kinds,
                }}
              />
            )}
          </div>
          {/* resume label */}
          <span
            className="ml-2 rr-t2b cursor-pointer"
            style={{ marginTop: "2px" }}
            onClick={selectState.toggleAll}
          >
            {t({ en: "Clips", fr: "Coupures" })} ({totalPagesCount})
          </span>
        </div>
        {loading ? (
          <p>{t(appTexts.loading)}</p>
        ) : pages.length === 0 ? (
          <NothingBlock>
            <p className="rr-t2 mb-0">
              {t(
                hasFilters
                  ? {
                      en: "No clips matching filters",
                      fr: "Aucune coupure correspondant aux filtres",
                    }
                  : {
                      en: "Your clips library is empty",
                      fr: "Votre bibliothèque de coupures est vide",
                    }
              )}
            </p>
          </NothingBlock>
        ) : (
          <GridContainer>
            {pages.map((page) => (
              <ClipCardVault
                key={page.id}
                id={page.id}
                kind={page.kind}
                date={
                  page.published_time
                    ? new Date(page.published_time)
                    : undefined
                }
                publicationName={page.publication_name}
                title={page.title}
                error={page.error?.code}
                image={addVersion(
                  page,
                  screenshotUrl(page.id, page.screenshot || "")
                )}
                {...getPreviewStats(page)}
                open={() => {
                  openDrawer({ clip_edit: page.id });
                }}
                loading={!page.crawled}
              />
            ))}
            {pages.length > 0 && (
              <div className="row mt-5 mb-5">
                <div className="col-12 col-lg-2"></div>
                <div className="col-12 col-lg-8">
                  <div className="d-flex justify-content-center mb-4">
                    <Pagination
                      currentPage={filters.offset / filters.limit}
                      totalPages={Math.ceil(totalPagesCount / filters.limit)}
                      onPageChange={(page: number) => {
                        filtersDispatch({
                          type: "SET_OFFSET",
                          payload: page * filters.limit,
                        });
                        window.scrollTo(0, 0);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-lg-2 text-center lg-text-right">
                  <select
                    disabled={loading}
                    className="form-control custom-select w-auto"
                    value={filters.limit}
                    onChange={(e) => {
                      filtersDispatch({
                        type: "SET_LIMIT",
                        payload: parseInt(e.target.value),
                      });
                    }}
                  >
                    <option value={10}>10 per page</option>
                    <option value={50}>50 per page</option>
                    <option value={100}>100 per page</option>
                  </select>
                </div>
              </div>
            )}
          </GridContainer>
        )}
      </div>
    </FullPage>
  );
}

function Pagination({
  currentPage,
  totalPages,
  onPageChange,
}: {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}) {
  const getPageNumbers = () => {
    const pages = [];
    if (totalPages <= 6) {
      for (let i = 0; i < totalPages; i++) {
        pages.push(i);
      }
    } else {
      if (currentPage <= 1) {
        pages.push(0, 1, 2, "...", totalPages - 1);
      } else if (currentPage >= totalPages - 2) {
        pages.push(0, "...", totalPages - 3, totalPages - 2, totalPages - 1);
      } else {
        pages.push(
          ...[
            0,
            currentPage == 3 ? 1 : currentPage > 3 ? "..." : undefined,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            currentPage < totalPages - 3 ? "..." : undefined,
            totalPages - 1,
          ].filter((v) => v !== undefined)
        );
      }
    }
    return pages;
  };

  return (
    <nav>
      <ul className="pagination my-0">
        <li className={`page-item ${currentPage === 0 ? "disabled" : ""}`}>
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage - 1)}
            disabled={currentPage === 0}
          >
            Previous
          </button>
        </li>
        {getPageNumbers().map((page, index) => (
          <li
            key={index}
            className={`page-item ${page === currentPage ? "active" : ""} ${
              page === "..." ? "disabled" : ""
            }`}
          >
            <button
              className="page-link"
              onClick={() => typeof page === "number" && onPageChange(page)}
              disabled={page === "..."}
            >
              {typeof page === "number" ? page + 1 : page}
            </button>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === totalPages - 1 ? "disabled" : ""
          }`}
        >
          <button
            className="page-link"
            onClick={() => onPageChange(currentPage + 1)}
            disabled={currentPage === totalPages - 1}
          >
            Next
          </button>
        </li>
      </ul>
    </nav>
  );
}
