/**
 * Feeds page
 *
 * This page is used to manage feeds and their items.
 */

import React from "react";
import { useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { useLoggedState, useDispatch } from "../hooks/useAppState";
import { FullPage } from "../components/LoggedLayout";
import { useLoading } from "../hooks/useLoading";
import * as actions from "../store/actions";
import useI18n from "../hooks/useI18n";
import { Plus, Rss, FilePlus, Users } from "react-feather";
import { format } from "date-fns";
import styled from "styled-components";
import theme from "../theme";
import {
  FeedItemDropdown,
  useFeedItemDropdown,
} from "../components/ui/FeedItemDropdown";
import { FeedItem, Feed } from "../store/types";
import useSocketIo from "../hooks/useSocketIo";
import { useFeedDropdown, FeedDropdown } from "../components/ui/FeedDropdown";
import { MoreVertical } from "react-feather";
import cls from "classnames";
import zustand from "zustand";
import { numberF } from "../utils/numberF";
import { useAppDrawer, DrawerState } from "../hooks/useAppDrawer";
import { Title } from "../components/ui/PageCard";
import { isSocial } from "../model";

type FilterKind = "all" | "web" | "social" | "other";

const DEFAULT_FILTERS: FilterKind[] = ["all"]; // social", "web", "other"];

type PageState = {
  selectedFilters: FilterKind[];
  selectedFeeds: string[];
  reset: () => void;
  setSelectedFeeds: (feeds: string[]) => void;
  toggleFeed: (feed: string) => void;
  toggleFilter: (filter: FilterKind) => void;
};

const usePageState = zustand<PageState>((set, get) => ({
  selectedFilters: DEFAULT_FILTERS,
  selectedFeeds: [],
  reset: () => set({ selectedFeeds: [], selectedFilters: DEFAULT_FILTERS }),
  setSelectedFeeds: (feeds: string[]) => set({ selectedFeeds: feeds }),
  toggleFeed: (feed: string) => {
    const feeds = get().selectedFeeds;
    set({
      selectedFeeds: feeds.includes(feed)
        ? feeds.filter((f: string) => f !== feed)
        : [...feeds, feed],
    });
  },
  toggleFilter: (filter: FilterKind) => {
    /* const filters = get().selectedFilters; */
    set({
      selectedFilters: [filter],
      /* filters.includes(filter)
       *   ? filters.filter((f: FilterKind) => f !== filter)
       *   : [...filters, filter], */
    });
  },
}));

export function Feeds(
  props: RouteComponentProps<{ workspaceId?: string; children: any }>
) {
  const state = usePageState();
  // we want to listen to the workspaceId changes
  useSocketIo({ workspaceId: props.workspaceId! });
  useEffect(() => {
    return () => {
      state.reset();
    };
  }, []);
  const t = useI18n();
  // Current workspace
  const workspace = useLoggedState(
    (s) => s.workspaces.find((w) => w.id === props.workspaceId) || s.workspace
  );
  const feeds = useLoggedState((s) => s.feeds);
  const items = useLoggedState((s) => s.feedItems);
  const dispatch = useDispatch();
  const [loading, load] = useLoading();
  const filteredItems = items
    .filter(
      (item) =>
        state.selectedFeeds.includes(item.feed_id) &&
        (state.selectedFilters.includes("all") ||
          state.selectedFilters.includes(
            item.kind === "web"
              ? "web"
              : isSocial(item.kind)
              ? "social"
              : "other"
          ))
    )
    .sort((a, b) => b.readership - a.readership);
  const selectedItems = useFeedItemDropdown();
  const unreadCount = items.filter((item) => item.state === "new").length;
  useEffect(() => {
    selectedItems.setItemIds(filteredItems.map((item) => item.id));
  }, [items, state.selectedFeeds, state.selectedFilters]);

  // Load feeds on entering dashboard
  useEffect(() => {
    dispatch({ type: "EXIT_PROJECT" });
    if (props.workspaceId) {
      load(
        // load the workspace
        dispatch(actions.workspaces.loadWorkspace(props.workspaceId))
          // then load the feeds
          .then(() =>
            dispatch(actions.feeds.listFeeds(props.workspaceId!))
              // then activate all the feeds
              .then((feeds) => {
                state.setSelectedFeeds(feeds.map((feed) => feed.id));
              })
          )
      );
    } else {
      dispatch({ type: "EXIT_WORKSPACE" });
      load(dispatch(actions.projects.loadProjects()));
    }
  }, [dispatch, load, props.workspaceId]);

  const handleAddFeed = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    load(
      dispatch(
        actions.feeds.createFeed(
          props.workspaceId!,
          (e.target as any).url.value
        )
      ).then((feed) => {
        state.setSelectedFeeds(
          state.selectedFeeds
            .filter((f: string) => f !== feed.id)
            .concat(feed.id)
        );
      })
    );
  };

  return (
    <FullPage>
      {loading || !workspace ? (
        <></>
      ) : (
        <>
          <div className="m-2 p-4">
            <div className="rr-t1">Monitoring</div>
            {t({
              en: (
                <p>
                  Add your monitoring feeds and easily include interesting
                  mentions in coverage reports.
                </p>
              ),
              fr: (
                <p>
                  Ajoutez vos flux RSS à surveiller pour inclure facilement les
                  mentions dans vos rapports.
                </p>
              ),
            })}
            <div className="mt-1">
              <form
                className="d-flex justify-content-start align-items-center gap"
                onSubmit={handleAddFeed}
              >
                <input
                  name="url"
                  type="text"
                  className="form-control mr-1"
                  placeholder="Feed URL"
                  style={{ maxWidth: "640px" }}
                />
                <button
                  className="d-flex align-items-center btn btn-primary btn"
                  // data-toggle="dropdown"
                  aria-expanded="false"
                  type="submit"
                >
                  <Plus height="18" className="mr-1" />
                  <div>
                    {t({
                      en: <>Add RSS feed</>,
                      fr: <>Ajouter un flux RSS</>,
                    })}
                  </div>
                </button>
              </form>
            </div>
            {feeds.length === 0 ? (
              <Help />
            ) : (
              <>
                <div className="mt-4">
                  {/* <h2>Your Feeds</h2> */}
                  {feeds.map((feed) => (
                    <FeedLine
                      selected={state.selectedFeeds.includes(feed.id)}
                      toggle={() => state.toggleFeed(feed.id)}
                      key={feed.id}
                      feed={feed}
                    />
                  ))}
                </div>
                <div className="mt-5 mb-3" style={{ padding: "0 22px" }}>
                  <div className="d-flex justify-content-between align-items-center">
                    {/* Select checkbox on the left */}
                    <div className="d-flex justify-content-between align-items-center">
                      {/* Select all checkbox */}
                      <div>
                        <Check
                          onClick={selectedItems.toggleAll}
                          checked={selectedItems.all}
                        />
                        {selectedItems.all}
                        {selectedItems.id === "all" && <FeedItemDropdown />}
                      </div>
                      {/* resume label */}
                      <span
                        className="ml-2 rr-t2b cursor-pointer"
                        style={{ marginTop: "2px" }}
                        onClick={selectedItems.toggleAll}
                      >
                        {t({ en: "Mentions", fr: "Mentions" })} ({unreadCount})
                      </span>
                    </div>
                    {/* Sort component on the right */}
                    <div>
                      <Filters />
                    </div>
                  </div>
                </div>
                {filteredItems.map((item) => (
                  <FeedItemCard key={item.id} item={item} />
                ))}
              </>
            )}
          </div>
        </>
      )}
    </FullPage>
  );
}

const FeedLine = ({
  feed,
  selected,
  toggle,
}: {
  feed: Feed;
  selected: boolean;
  toggle: () => void;
}) => {
  const dropdown = useFeedDropdown();
  return (
    <div
      className={cls("card mb-2", {
        "text-muted": !selected,
        "bg-light": !selected,
      })}
    >
      <div className="d-flex">
        <div
          className={cls("align-self-center cursor-pointer py-3 pl-4 pr-3", {
            "opacity-50": !selected,
          })}
          onClick={toggle}
        >
          <Check checked={selected} style={{ marginLeft: "-0px" }} />
        </div>
        <div
          onClick={toggle}
          className={cls("cursor-pointer align-self-center flex-grow-1 pl-1", {
            "opacity-50": !selected,
          })}
        >
          <div>
            <Rss width={14} className="mr-1 mb-1" />
            <span className="user-select-none">
              {feed.title || "Not fetched yet."}
            </span>
          </div>
        </div>
        <div
          className="align-self-center cursor-pointer pl-2 pr-3"
          style={{
            position: "relative",
            // width: "32px",
            minWidth: "32px",
            //  maxWidth: "32px",
            opacity: 1,
          }}
          onClick={() => dropdown.select(feed.id)}
        >
          <MoreVertical height={16} width={16} />
          {dropdown.isSelected(feed.id) && <FeedDropdown feed={feed} />}
        </div>
      </div>
    </div>
  );
};

/**
 * Display a FeedItem.
 */
const FeedItemCard = ({ item }: { item: FeedItem }) => {
  const t = useI18n();
  const openDrawer = useAppDrawer((s: DrawerState) => s.open);
  const selectState = useFeedItemDropdown();
  const selected = selectState.all || selectState.selectedItems[item.id];
  return (
    <Card
      className={cls("mb-3 card cursor-pointer", {
        "bg-light": item.state !== "new",
      })}
      onClick={() => openDrawer({ feed_item: item.id })}
    >
      <div className="card-body">
        <div className="d-flex">
          <div className="d-flex flex-column justify-content-between mr-1">
            <div
              style={{ position: "relative", maxWidth: "28px" }}
              onClick={(e) => {
                e.stopPropagation();
                selectState.toggle(item.id);
              }}
            >
              <Check
                checked={selected}
                style={{ marginLeft: "2px" }}
                aria-expanded="false"
              />
              {selectState.id === item.id && <FeedItemDropdown />}
            </div>
            {item.state === "published" && (
              <div
                style={{
                  position: "relative",
                  maxWidth: "28px",
                  paddingLeft: "2px",
                }}
                className="text-muted mb-n1"
                title={t({ en: "Added to a report" })}
              >
                <FilePlus width={18} />
              </div>
            )}
          </div>
          <div className="flex-grow-1">
            <div>
              {item.state === "new" ? (
                <Title
                  className="cursor-pointer"
                  onClick={() => openDrawer({ feed_item: item.id })}
                >
                  {item.title?.replace(/^(…|\.\.\.)/, "") || "No title"}
                </Title>
              ) : (
                <div
                  className="rr-t2 cursor-pointer text-muted"
                  onClick={() => openDrawer({ feed_item: item.id })}
                >
                  {item.title?.replace(/^(…|\.\.\.)/, "") || "No title"}
                </div>
              )}
            </div>
            <div className="mt-1 rr-t3 rr-mid-grey text-muted  d-flex align-items-center">
              <FeedItemSource item={item} />
              -  {format(new Date(item.publication_date), "d MMM yyyy")}
            </div>
            {item.title !== item.description && (
              <div
                className="rr-t6 mt-1"
                style={{
                  fontSize: 14,
                  height: "3em",
                  overflow: "hidden",
                  whiteSpace: "initial",
                  textOverflow: "ellipsis",
                  boxSizing: "content-box",
                  boxOrient: "vertical",
                  lineClamp: 2,
                }}
              >
                {item.description?.replace(/^(…|\.\.\.)/, "")}
              </div>
            )}
          </div>
          {/* Stats and button grouped */}
          <div className="my-3 ml-md-2 d-flex justify-content-between my-md-3">
            <Stats className="ml-0 d-flex align-self-center">
              <>
                <Separator />
                <StatBlock>
                  <div>
                    <Users width={16} height={16} />
                  </div>
                  <div className="d-none d-sm-block rr-t4">
                    {t({
                      en: "Readership",
                      fr: "Audience",
                    })}
                  </div>
                  <div className="rr-t2b rr-mid-grey">
                    {item.readership > 0 ? numberF(item.readership) : "NA"}
                  </div>
                </StatBlock>
              </>
              <Separator />
            </Stats>
            <div className="align-self-center ml-3">
              <a
                className="btn btn-primary btn-sm text-nowrap"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  openDrawer({ feed_item: item.id });
                }}
              >
                <span className="d-375-none">
                  {t({ en: "Details", fr: "Détails" })}
                </span>
                <span className="d-none d-375-block">
                  {t({ en: "Details", fr: "Détails" })}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

const Help = () => {
  const t = useI18n();
  return t({
    en: <HelpEn />,
    fr: <HelpFr />,
  });
};

const HelpEn = () => {
  return (
    <div className="card- mt-4" style={{ maxWidth: 800 }}>
      <div className="card-body">
        <h3 className="rr-t2b">All media monitoring alerts in one place.</h3>

        <p className="">
          You can now directly connect most monitoring tools with ReachReport.
          Once connected, ReachReport will import all mentions, allowing you to
          manage alerts in one place without the need to switch between tools.
          You can also easily include relevant items in a coverage report.
        </p>

        <h3 className="rr-t2b">
          Better at managing Google Alerts than Google.
        </h3>

        <p>
          Google displays a list of all pages that contain the phrase you
          monitor, offering no option to discard individual alerts. Within
          ReachReport, processing alerts is as simple as managing your email.
          Additionally, we provide domain information for each alert. This makes
          finding relevant mentions easier.
        </p>

        <h3 className="rr-t2b">How does it work?</h3>

        <p>
          You can add RSS feeds from monitoring tools to ReachReport. Most
          monitoring services such as Google Alerts, Mention, Talkwalker and
          BuzzSumo provide RSS feeds.
        </p>

        <p>
          ReachReport will only import information via the feed. We cannot
          access your account or make any changes to it.
        </p>

        <h3 className="rr-t2b">What does it cost?</h3>

        <p>
          This feature is included in your subscription at no additional cost.
        </p>

        <h3 className="rr-t2b">How to set it up?</h3>

        <p>
          Create a new client workspace for each campaign. Within this
          workspace, add a new RSS feed for each service and keyword you are
          monitoring for the client. This way, you will have all alerts
          organized by client in one place.
        </p>
        <p>
          Please{" "}
          <a className="text-underline" href="mailto:info@reachreport.io">
            reach out
          </a>{" "}
          if you need a hand adding RSS feeds from Google or any other service.
          We would be happy to help with the setup.
        </p>

        <h3 className="rr-t2b">How to add my Google Alerts?</h3>
        <p>Follow this small video tutorial, it's really easy:</p>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/948711896?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Add Google Alert to ReachReport.io"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <p className="text-center text-muted">
          Note: Items may take one hour or more to start being feeded by google.
        </p>
      </div>
    </div>
  );
};

const HelpFr = () => {
  return (
    <div className="card- mt-4" style={{ maxWidth: 800 }}>
      <div className="card-body">
        <h3 className="rr-t2b">
          Tous vos monitoring medias en un seul endroit.
        </h3>

        <p className="">
          Vous pouvez connecter la plupart des outils de veille à ReachReport.
          Une fois connecté, ReachReport importera toutes les mentions et vous
          permettra de gérer vos alertes ici sans avoir besoin de passer d'un
          outil à l'autre. Vous pouvez aussi aisément inclure les élements qui
          vous intéresse dans vos rapports.
        </p>

        <h3 className="rr-t2b">
          Meilleure gestion des alertes Google que Google.
        </h3>

        <p>
          Google affiche une liste de toutes les pages contenant la phrase que
          vous surveillez, sans option pour supprimer des alertes individuelles.
          Avec ReachReport, le traitement des alertes est aussi simple que la
          gestion de vos emails. De plus, nous fournissons des informations sur
          le domaine pour chaque alerte, ce qui facilite la recherche des
          mentions pertinentes.
        </p>

        <h3 className="rr-t2b">Comment ça marche ?</h3>

        <p>
          Vous pouvez ajouter des flux RSS à suivre. La plupart des outils de
          veille comme Google Alerts, Mention, Talkwalker et BuzzSumo propose un
          flux RSS.
        </p>

        <p>ReachReport lira directement les flux que vous lui proposerez.</p>

        <h3 className="rr-t2b">Combien cela coûte-t-il ?</h3>

        <p>
          Cette fonction est incluses dans tous nos abonnements, sans coût
          additionnel !
        </p>

        <h3 className="rr-t2b">Comment le configurer ?</h3>

        <p>
          Créez un espace client pour chaque client ou campagne. Dans cet
          espace, ajoutez un nouveau flux RSS pour chaque service et mots clefs
          que vous surveillez pour votre client. De cette manière toutes vos
          alertes seront organisées et centralisées par client ou par campagne.
        </p>
        <p>
          <a className="text-underline" href="mailto:info@reachreport.io">
            Contactez-nous
          </a>{" "}
          si vous avez besoin d'aide pour ajouter des flux RSS de Google ou
          d'autres services. Nous vous aiderons avec plaisir.
        </p>

        <h3 className="rr-t2b">Comment lier mes Google Alerts ?</h3>
        <p>Suivez cette petite vidéo explicative, c'est très simple :</p>
        <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
          <iframe
            src="https://player.vimeo.com/video/948711896?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture; clipboard-write"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
            title="Lier Google Alert à ReachReport.io"
          ></iframe>
        </div>
        <script src="https://player.vimeo.com/api/player.js"></script>
        <p className="text-center text-muted">
          Note : Pour les nouvelles alertes, il se peut que vous ayez à attendre
          une heure ou plus pour que Google commence à alimenter votre flux et
          que les éléments soient aspirés par ReachReport.
        </p>
      </div>
    </div>
  );
};

const Filters = () => {
  const t = useI18n();
  return (
    <div>
      <Filter kind="all">{t({ en: "All", fr: "Tout" })}</Filter>
      <Filter kind="web">{t({ en: "Web", fr: "Web" })}</Filter>
      <Filter kind="social">{t({ en: "Social", fr: "Social" })}</Filter>
      <Filter kind="other">{t({ en: "Other", fr: "Autre" })}</Filter>
    </div>
  );
};

const Filter = (props: { kind: FilterKind; children: any }) => {
  const selected = usePageState((s: PageState) =>
    s.selectedFilters.includes(props.kind)
  );
  const toggleFilter = usePageState((s: PageState) => s.toggleFilter);
  return (
    <a
      href="/#"
      className={cls("badge rounded-pill py-1 px-2 mr-1", {
        "badge-primary": selected,
        "btn-outline-primary": !selected,
      })}
      onClick={(e) => {
        e.preventDefault();
        toggleFilter(props.kind);
      }}
    >
      {props.children}
    </a>
  );
};

/** Feed source */
const FeedItemSource = ({ item }: { item: FeedItem }) => {
  const url = new URL(item.link);
  const favIcon = url.origin + "/favicon.ico";
  return (
    <span className="d-inline-flex align-items-center">
      <object
        type="image/x-icon"
        data={favIcon}
        width="16"
        height="16"
        aria-label="Fav icon"
        className="mr-1"
      >
        <img
          alt=" "
          src="/favicon.ico"
          style={{ width: "16px", height: "16px" }}
        />
      </object>
      <span>{url.hostname}</span>
    </span>
  );
};

const Card = styled.div.attrs((props) => props)`
  border: 2px solid ${theme.lightGrey};

  &:hover {
    border: 2px solid ${theme.blue};
  }

  &.disabled {
    border: 2px solid rgba(0, 0, 0, 0.05);
  }

  &.loading {
    border: 2px dashed ${theme.lightGrey};
  }

  .card-body {
    padding: 10px;
    oveflow: hidden;
    @media (min-width: 375px) {
      padding: 15px;
    }
    @media (min-width: 768px) {
      padding: 1.25rem;
    }
  }

  .card-body > .d-flex {
    row-gap: 10px;
    column-gap: 10px;
    @media (min-width: 768px) {
      row-gap: 16px;
      column-gap: 16px;
    }
  }
`;

export const Check = styled.div<{ checked?: boolean }>`
  margin: 2px;
  width: 18px;
  height: 18px;
  border-radius: 4px;
  cursor: pointer;
  ${(props) =>
    props.checked
      ? `
    background-color: ${theme.blue};
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
    background-position: center;
    background-size: 14px 14px;
    background-repeat: no-repeat;
  `
      : `
      border: 2px solid ${theme.grey};`}
`;

const Stats = styled.div`
  color: ${theme.midGrey};
`;

const StatBlock = styled.div`
  text-align: center;
  width: auto;
  @media (min-width: 768px) {
    width: auto;
    &:first-child {
      padding: 0px 10px;
    }
    &:last-child {
      padding: 0px 0px 0px 10px;
    }
  }
  @media (min-width: 1200px) {
    width: 100px;
  }
`;

const Separator = styled.div`
  width: 2px;
  background-color: #e9e9e9;
  margin: 16px;
`;
