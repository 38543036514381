import { setAuthToken } from "../api";
import { State, Action } from "./types";

export const initialAppState: State = {
  session: null,
  user: null,
  teams: [],
  notifications: {},
  workspaces: [],
  workspace: null,
  projects: [],
  project: null,
  pages: [],
  totalPagesCount: 0,
  pageId: null,
  shares: [],
  memberships: [],
  designs: [],
  refreshing: false,
  firstClipFlag: false,
  feeds: [],
  feedItems: [],
};

export const appStateReducer = (
  state: State = initialAppState,
  action: Action
): State => {
  switch (action.type) {
    case "INIT": {
      return state;
    }
    // SESSION ACTIONS
    case "SIGNOUT": {
      setAuthToken(null);
      return { ...initialAppState };
    }
    case "SET_SESSION": {
      const { session } = action;
      setAuthToken(session.token);
      return { ...state, session };
    }
    // TEAM ACTIONS
    case "SET_TEAMS": {
      const { teams } = action;
      return { ...state, teams };
    }
    case "UPD_TEAM": {
      const { team } = action;
      return {
        ...state,
        teams: state.teams.map((t) => (t.id === team.id ? team : t)),
      };
    }
    // USER ACTIONS
    case "SET_USER": {
      const { user } = action;
      return { ...state, user };
    }
    // WORKSPACE ACTIONS
    case "SET_WORKSPACES": {
      const { workspaces } = action;
      return { ...state, workspaces };
    }
    case "ADD_WORKSPACE": {
      const { workspace } = action;
      return { ...state, workspaces: state.workspaces.concat(workspace) };
    }
    case "DEL_WORKSPACE": {
      const { id } = action;
      const { workspace: currentWorkspace } = state;
      return {
        ...state,
        workspaces: state.workspaces.filter((p) => p.id !== id),
        workspace:
          currentWorkspace && currentWorkspace.id === id
            ? null
            : currentWorkspace,
      };
    }
    case "SET_WORKSPACE": {
      const { workspace, projects } = action;
      return {
        ...state,
        shares: [],
        feeds: [],
        feedItems: [],
        workspace,
        projects: projects || state.projects,
      };
    }
    case "EXIT_WORKSPACE": {
      return {
        ...state,
        project: null,
        workspace: null,
      };
    }
    // PROJECT ACTIONS
    case "SET_PROJECTS": {
      const { projects } = action;
      return { ...state, projects };
    }
    case "UPD_PROJECT": {
      const { project } = action;
      return {
        ...state,
        projects: state.projects.map((p) =>
          p.id === project.id ? project : p
        ),
        project: state.project?.id === project.id ? project : state.project,
      };
    }
    case "ADD_PROJECT": {
      const { project } = action;
      return { ...state, projects: state.projects.concat(project) };
    }
    case "DEL_PROJECT": {
      const { id } = action;
      const { project: currentProject } = state;
      return {
        ...state,
        projects: state.projects.filter((p) => p.id !== id),
        project:
          currentProject && currentProject.id === id ? null : currentProject,
      };
    }
    case "SET_PROJECT": {
      const { project, pages } = action;
      return { ...state, shares: [], project, pages: pages || state.pages };
    }
    case "EXIT_PROJECT": {
      return {
        ...state,
        project: null,
      };
    }
    // PAGE ACTIONS
    case "SET_PAGES": {
      const { pages, totalPagesCount } = action;
      return {
        ...state,
        pages,
        totalPagesCount: totalPagesCount || pages.length,
      };
    }
    case "SET_PAGE": {
      const { page } = action;
      return { ...state, pageId: page ? page.id : null };
    }
    case "UPD_PAGE": {
      const { page } = action;
      let result = { ...state };
      // detect first page crawled
      const firstWorkspace = state.workspaces.length === 1;
      const firstProject = state.projects.length === 1;
      const crawledPages = state.pages.filter((p) => p.url && p.crawled).length;
      if (
        firstWorkspace &&
        firstProject &&
        crawledPages === 0 &&
        page.crawled &&
        !["custom", "import", "press", "radio", "tv", "podcast"].includes(
          page.kind
        )
      ) {
        result.firstClipFlag = true;
      }
      result.pages = result.pages.map((p) => (p.id === page.id ? page : p));
      return result;
    }
    case "UPD_PAGE_STATS": {
      const { pageId, stats } = action;
      const result = { ...state };
      result.pages = result.pages.map((p) =>
        p.id === pageId ? { ...p, stats } : p
      );
      return result;
    }
    case "ADD_PAGE": {
      const { page } = action;
      // if page already exists, update it
      if (state.pages.find((p) => p.id === page.id)) {
        return {
          ...state,
          pages: state.pages.map((p) => (p.id === page.id ? page : p)),
        };
      }
      // if in vault, push front
      if (state.project === null) {
        return {
          ...state,
          pages: [page].concat(state.pages),
        };
      }
      // if page does not exist, add it at the end
      let pages = state.pages.concat();
      pages.splice(page.pos || 0, 0, page);
      pages = pages.map((p, i) => ({ ...p, pos: i }));
      return { ...state, pages };
    }
    case "DEL_PAGE": {
      const { pageId } = action;
      const newPages = state.pages
        .filter((p) => p.id !== pageId)
        .map((p, i) => ({
          ...p,
          pos: i,
        }));
      return { ...state, pages: newPages };
    }
    // SHARE ACTIONS
    case "SET_SHARES": {
      const { shares } = action;
      return { ...state, shares };
    }
    case "ADD_SHARE": {
      const { share } = action;
      const shares = state.shares.concat([share]);
      return { ...state, shares };
    }
    case "DEL_SHARE": {
      const { id } = action;
      const shares = state.shares.filter((s) => s.id !== id);
      return { ...state, shares };
    }
    // MEMBERSHIP ACTIONS
    case "SET_MEMBERSHIPS": {
      const { memberships } = action;
      return { ...state, memberships };
    }
    case "UPD_MEMBERSHIP": {
      const { membership } = action;
      const result = { ...state };
      result.memberships = result.memberships.map((p) =>
        p.user_id === membership.user_id ? membership : p
      );
      return result;
    }
    case "ADD_MEMBERSHIP": {
      const { membership } = action;
      let memberships = state.memberships
        .filter((m) => m.user_id !== membership.user_id)
        .concat(membership);
      return { ...state, memberships };
    }
    case "DEL_MEMBERSHIP": {
      const { user_id } = action;
      const newMemberships = state.memberships.filter(
        (p) => p.user_id !== user_id
      );
      return { ...state, memberships: newMemberships };
    }
    // DESIGN ACTIONS
    case "SET_DESIGNS": {
      const { designs } = action;
      return { ...state, designs };
    }
    case "UPD_DESIGN": {
      const { design } = action;
      const result = { ...state };
      result.designs = result.designs.map((p) =>
        p.id === design.id ? design : p
      );
      return result;
    }
    case "ADD_DESIGN": {
      const { design } = action;
      let designs = state.designs
        .filter((m) => m.id !== design.id)
        .concat(design);
      return { ...state, designs };
    }
    case "DEL_DESIGN": {
      const { design_id } = action;
      const newDesigns = state.designs.filter((p) => p.id !== design_id);
      return { ...state, designs: newDesigns };
    }
    case "SET_REFRESHING": {
      const { value } = action;
      return { ...state, refreshing: value };
    }
    case "SET_FIRST_CLIP_FLAG": {
      const { value } = action;
      return { ...state, firstClipFlag: value };
    }
    // FEED ACTIONS
    case "SET_FEEDS": {
      const { feeds } = action;
      return { ...state, feeds };
    }
    case "ADD_FEED": {
      const { feed } = action;
      const exists = state.feeds.find((f) => f.id === feed.id);
      const updated = exists
        ? state.feeds.map((f) => (f.id === feed.id ? feed : f))
        : state.feeds.concat(feed);
      return { ...state, feeds: updated };
    }
    case "DEL_FEED": {
      const { feed_id } = action;
      const feedItems = state.feedItems.filter((fi) => fi.feed_id !== feed_id);
      const news = state.feedItems.filter(
        (fi) => fi.feed_id === feed_id && fi.state === "new"
      ).length;
      return {
        ...state,
        feeds: state.feeds.filter((f) => f.id !== feed_id),
        feedItems,
        notifications: incrementNotifications(
          state.notifications,
          state.workspace?.id || "",
          -news
        ),
      };
    }
    case "SET_FEED_ITEMS": {
      const { items } = action;
      return { ...state, feedItems: items };
    }
    case "UPD_FEED_ITEM": {
      const { item } = action;
      const old = state.feedItems.find((fi) => fi.id === item.id);
      const news = old && old.state === "new" && item.state !== "new" ? -1 : 0;
      return {
        ...state,
        feedItems: state.feedItems.map((fi) => (fi.id === item.id ? item : fi)),
        notifications: incrementNotifications(
          state.notifications,
          state.workspace?.id || "",
          news
        ),
      };
    }
    case "DEL_FEED_ITEM": {
      const { id } = action;
      const item = state.feedItems.find((fi) => fi.id === id);
      const feedItems = state.feedItems.filter((fi) => fi.id !== id);
      return {
        ...state,
        feedItems,
        notifications: incrementNotifications(
          state.notifications,
          state.workspace?.id || "",
          item && item.state === "new" ? -1 : 0
        ),
      };
    }
    // NOTIFICATION ACTIONS
    case "SET_NOTIFICATIONS": {
      const { notifications } = action;
      return { ...state, notifications };
    }
  }
  // @ts-ignore
  return state;
};

const incrementNotifications = (
  notifications: State["notifications"],
  workspace_id: string,
  inc: number
) => {
  const workspace = notifications[workspace_id] || 0;
  return { ...notifications, [workspace_id]: Math.max(0, workspace + inc) };
};
